import { APICore } from './apiCore';
const api = new APICore();

// contestQuestion
// eslint-disable-next-line no-empty-pattern
function contestQuestions(params: {
    question: string,
    regions: string,
    category: string,
    type: string,
    sortBy: string,
    limit: number,
    page: number;
}) {
    const baseUrl = `/contestQuestion`;
    return api.get(`${baseUrl}`, params);

}
function addContestQuestion(params: { question: string, type: string, media: string, category: string, options: Array<{ option: string, selected: boolean }>, level: string, regions: Array<any> }) {
    const baseUrl = `/contestQuestion/`;
    return api.create(`${baseUrl}`, params);

}
function updateContestQuestion(params: { question: string, type: string, media: string, category: string, options: Array<{ option: string, selected: boolean }>, level: string, regions: Array<any>, updateMedia: boolean }, id: string) {
    const baseUrl = `/contestQuestion/${id}`;
    return api.updatePatch(`${baseUrl}`, params);

}

function deleteContestQuestion(id: string) {
    const baseUrl = `/contestQuestion/${id}`;
    return api.delete(`${baseUrl}`);

}

function uploadContestQuestionFile(params: { file: File[] }) {
    const baseUrl = `/contestQuestion/uploadContestQuestionMedia`;
    return api.uploadFile(`${baseUrl}`, params);
}

function importContestQuestions(params: {
    regions: string,
    category: string,
    file: File,
}) {
    const baseUrl = `/contestQuestion/importQuestions`;
    return api.createWithFile(`${baseUrl}`, params);
}



export { contestQuestions, addContestQuestion, updateContestQuestion, deleteContestQuestion, uploadContestQuestionFile, importContestQuestions };
