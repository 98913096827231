import { APICore } from './apiCore';
const api = new APICore();

// category
function reportedIssues(params: {
    userId: string,
    issue: string,
    sortBy: string,
    limit: number,
    page: number,
}) {
    const baseUrl = `/reportIssue`;
    return api.get(`${baseUrl}`, params);

}




export { reportedIssues };
