import { APICore } from './apiCore';
const api = new APICore();

// winnerCoinRank
// eslint-disable-next-line no-empty-pattern


function winnerCoinRanks(params: {
    name: string,
    status: string,
    sortBy: string,
    limit: number,
    page: number,
}) {
    const baseUrl = `/winnerCoinRank`;
    return api.get(`${baseUrl}`, params);

}
function addWinnerCoinRank(params: {
    name: string,
    rankWiseCoins: Array<number>,
}) {
    const baseUrl = `/winnerCoinRank`;
    return api.create(`${baseUrl}`, params);

}
function updateWinnerCoinRank(params: {
    status: string,
    name: string,
    rankWiseCoins: Array<number>,

}, id: string) {
    const baseUrl = `/winnerCoinRank/${id}`;
    return api.updatePatch(`${baseUrl}`, params);

}

function deleteWinnerCoinRank(id: string) {
    const baseUrl = `/winnerCoinRank/${id}`;
    return api.delete(`${baseUrl}`);

}

function uploadWinnerCoinRankFile(params: { file: File[] }) {
    const baseUrl = `/winnerCoinRank/uploadWinnerCoinRankImage`;
    return api.uploadFile(`${baseUrl}`, params);
}



export { winnerCoinRanks, addWinnerCoinRank, updateWinnerCoinRank, deleteWinnerCoinRank, uploadWinnerCoinRankFile };
