import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';

// helpers
import {
    quizQuestions as quizQuestionsApi,
    addQuizQuestion as addQuizQuestionApi,
    updateQuizQuestion as updateQuizQuestionApi,
    deleteQuizQuestion as deleteQuizQuestionApi,
    importQuizQuestions as importQuizQuestionsApi,
} from '../../helpers';

// actions
import { quizQuestionApiResponseSuccess, quizQuestionApiResponseError } from './actions';

// constants
import { QuizQuestionActionTypes } from './constants';

interface QuizQuestionData {
    payload: {
        id: string;
        question: string;
        type: string;
        category: string;
        level: string;
        media: string;
        updateMedia: boolean;
        options: Array<{ option: string, selected: boolean }>;
        regions: any;
        sortBy: string
        limit: number
        page: number
        file: File
    };
    type: string;
}



/**
 * QuizQuestion list
 */
function* quizQuestionList({ payload: { question, regions: string, category, sortBy, limit, page, type } }: QuizQuestionData): SagaIterator {
    try {

        const response = yield call(quizQuestionsApi, { question, regions: string, category, sortBy, limit, page, type });

        yield put(quizQuestionApiResponseSuccess(
            QuizQuestionActionTypes.QUIZ_QUESTION_LIST,
            response.data.results,
            response.data.page,
            response.data.limit,
            response.data.totalPages,
            response.data.totalResults,
            response.data.categories,
        ));
    } catch (error: any) {
        yield put(quizQuestionApiResponseError(QuizQuestionActionTypes.QUIZ_QUESTION_LIST, error));

    }
}

/**
 * Add QuizQuestion
 */
function* addQuizQuestion({ payload: { question, type, options, level, regions, category, media } }: QuizQuestionData): SagaIterator {
    try {

        const response = yield call(addQuizQuestionApi, { question, type, options, level, category, regions, media });

        yield put(quizQuestionApiResponseSuccess(
            QuizQuestionActionTypes.ADD_QUIZ_QUESTION,
            response.data.data
        ));
    } catch (error: any) {
        yield put(quizQuestionApiResponseError(QuizQuestionActionTypes.ADD_QUIZ_QUESTION, error));

    }
}

/**
 * Update QuizQuestion
 */
function* updateQuizQuestion({ payload: { id, question, type, category, options, level, regions, media, updateMedia } }: QuizQuestionData): SagaIterator {
    try {

        const response = yield call(updateQuizQuestionApi, { question, category, type, options, level, regions, media, updateMedia }, id);

        yield put(quizQuestionApiResponseSuccess(
            QuizQuestionActionTypes.UPDATE_QUIZ_QUESTION,
            response.data.data
        ));
    } catch (error: any) {
        yield put(quizQuestionApiResponseError(QuizQuestionActionTypes.UPDATE_QUIZ_QUESTION, error));

    }
}
/**
 * Delete QuizQuestion
 */
function* deleteQuizQuestion({ payload: { id } }: QuizQuestionData): SagaIterator {
    try {

        yield call(deleteQuizQuestionApi, id);
        yield put(quizQuestionApiResponseSuccess(
            QuizQuestionActionTypes.DELETE_QUIZ_QUESTION,
            id
        ));
    } catch (error: any) {
        yield put(quizQuestionApiResponseError(QuizQuestionActionTypes.DELETE_QUIZ_QUESTION, error));

    }
}
/**
 * Import Quiz Questions
 */
function* importQuizQuestions({ payload: { 
    regions,
    category,
    file, } }: QuizQuestionData): SagaIterator {
    try {

        const response = yield call(importQuizQuestionsApi, {
            regions,
            category,
            file,
        });
        yield put(quizQuestionApiResponseSuccess(
            QuizQuestionActionTypes.IMPORT_QUIZ_QUESTION,
            response.data.data
        ));
    } catch (error: any) {
        yield put(quizQuestionApiResponseError(QuizQuestionActionTypes.IMPORT_QUIZ_QUESTION, error));

    }
}




export function* watchQuizQuestionList() {
    yield takeEvery(QuizQuestionActionTypes.QUIZ_QUESTION_LIST, quizQuestionList);
}

export function* watchAddQuizQuestion() {
    yield takeEvery(QuizQuestionActionTypes.ADD_QUIZ_QUESTION, addQuizQuestion);
}

export function* watchUpdateQuizQuestion() {
    yield takeEvery(QuizQuestionActionTypes.UPDATE_QUIZ_QUESTION, updateQuizQuestion);
}

export function* watchDeleteQuizQuestion() {
    yield takeEvery(QuizQuestionActionTypes.DELETE_QUIZ_QUESTION, deleteQuizQuestion);
}

export function* watchImportQuizQuestions() {
    yield takeEvery(QuizQuestionActionTypes.IMPORT_QUIZ_QUESTION, importQuizQuestions);
}




function* quizQuestionSaga() {
    yield all([fork(watchQuizQuestionList), fork(watchAddQuizQuestion), fork(watchUpdateQuizQuestion), fork(watchDeleteQuizQuestion), fork(watchImportQuizQuestions)]);
}

export default quizQuestionSaga;
