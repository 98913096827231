import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';

// helpers
import {
    dashboard as dashboardApi,
    getUserGraph as getUserGraphApi,
} from '../../helpers';

// actions
import { dashboardApiResponseSuccess, dashboardApiResponseError } from './actions';

// constants
import { DashboardActionTypes } from './constants';

interface DashboardData {
    payload: {
        range: string
        userType: string
    };
    type: string;
}

/**
 * Dashboard list
 */
// eslint-disable-next-line no-empty-pattern
function* dashboardList({ payload: { } }: DashboardData): SagaIterator {
    try {

        const response = yield call(dashboardApi, {});
        yield put(dashboardApiResponseSuccess(
            DashboardActionTypes.DASHBOARD_DATA,
            response.data.userData,
            response.data.contestData,
            response.data.quizData,
            response.data.predictionData,
            response.data.supportData,
            response.data.questionData,
            response.data.categoriesCount,
            response.data.userGraphData,
            response.data.gameTraffic,
        ));
    } catch (error: any) {
        yield put(dashboardApiResponseError(DashboardActionTypes.DASHBOARD_DATA, error));

    }
}
function* userGraphData({ payload: { range, userType } }: DashboardData): SagaIterator {
    try {

        const response = yield call(getUserGraphApi, { range, userType });
        yield put(dashboardApiResponseSuccess(
            DashboardActionTypes.USER_GRAPH_DATA,
            {},
            {},
            {},
            {},
            {},
            {},
            {},
            response.data.userGraphData,
            {},
        ));
    } catch (error: any) {
        yield put(dashboardApiResponseError(DashboardActionTypes.USER_GRAPH_DATA, error));

    }
}

export function* watchShowDashboard() {
    yield takeEvery(DashboardActionTypes.DASHBOARD_DATA, dashboardList);
}
export function* watchUserGraphData() {
    yield takeEvery(DashboardActionTypes.USER_GRAPH_DATA, userGraphData);
}


function* dashboardSaga() {
    yield all([fork(watchShowDashboard), fork(watchUserGraphData)]);
}

export default dashboardSaga;
