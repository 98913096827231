import { APICore } from './apiCore';
const api = new APICore();

// user
// eslint-disable-next-line no-empty-pattern


function users(params: {
    email: string,
    isRegistered: boolean,
    sortBy: string,
    limit: number,
    page: number,
}) {
    const baseUrl = `/users`;
    return api.get(`${baseUrl}`, params);

}
function showUser(params: {
    sortBy: string,
    limit: number,
    page: number,
}, id:string) {
    const baseUrl = `/users/${id}`;
    return api.get(`${baseUrl}`, params);

}





export { users, showUser };
