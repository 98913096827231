export enum QuizActionTypes {
    API_RESPONSE_SUCCESS = '@@quiz/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR = '@@quiz/API_RESPONSE_ERROR',

    QUIZ_LIST = '@@quiz/QUIZ_LIST',
    ADD_QUIZ = '@@quiz/ADD_QUIZ',
    SHOW_QUIZ = '@@quiz/SHOW_QUIZ',
    UPDATE_QUIZ = '@@quiz/UPDATE_QUIZ',
    DELETE_QUIZ = '@@quiz/DELETE_QUIZ',
}
