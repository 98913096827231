// constants
import { ContestActionTypes } from './constants';

export interface ContestActionType {
    type:
    | ContestActionTypes.API_RESPONSE_SUCCESS
    | ContestActionTypes.API_RESPONSE_ERROR
    | ContestActionTypes.CONTEST_LIST
    | ContestActionTypes.ADD_CONTEST
    | ContestActionTypes.UPDATE_CONTEST
    | ContestActionTypes.SHOW_CONTEST
    | ContestActionTypes.DELETE_CONTEST
    | ContestActionTypes.UPDATE_CONTEST_STATUS
    payload: {} | string;
}


// common success
export const contestApiResponseSuccess = (actionType: string, data: any, page: number = 0, limit: number = 0, totalPages: number = 0, totalResults: number = 0, categories: any = []): ContestActionType => ({
    type: ContestActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data, page, limit, totalPages, totalResults, categories },
});
// common error
export const contestApiResponseError = (actionType: string, error: string): ContestActionType => ({
    type: ContestActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

interface ContestData {
    id: string,
    name: string,
    region: string,
    contestImage: string,
    category: string,
    joining_fee: number,
    duration: number,
    marking: number,
    negative_marking: number,
    pool_size: number,
    status: string,
    contest_question_count: number,
    questions: Array<string>,
    schedules: Array<any>,
    winnerAmount: Array<number>,
    lifeLineCharge: number,
    startDate: Date,
    endDate: Date,
    startTime:Date,
    endTime:Date,
    // resultDate: Date,
    winCoins: Number,
}
interface ContestUpdateData {
    id: string,
    name: string,
    contestImage: string,
    joining_fee: number,
    duration: number,
    updateImage: boolean,
    schedules: any,
    lifeLineCharge: number,
    startDate: Date,
    endDate: Date,
    resultDate: Date,
    winCoins: Number,
}

export const addContest = ({
    name,
    region,
    contestImage,
    category,
    joining_fee,
    duration,
    marking,
    negative_marking,
    pool_size,
    contest_question_count,
    questions,
    schedules,
    winnerAmount,
    lifeLineCharge,
    startDate,
    endDate,
    startTime,
    endTime,
    // resultDate,
    winCoins,
}: ContestData
): ContestActionType => ({
    type: ContestActionTypes.ADD_CONTEST,
    payload: {
        name,
        region,
        contestImage,
        category,
        joining_fee,
        duration,
        marking,
        negative_marking,
        pool_size,
        contest_question_count,
        questions,
        schedules,
        winnerAmount,
        lifeLineCharge,
        startDate,
        endDate,
        startTime,
        endTime,
        // resultDate,
        winCoins,
    },
});

interface FiltersData {
    name: string,
    status: string,
    visibility_status: string,
    region: string,
    category: any,
    sortBy: string,
    limit: number,
    page: number,
};

export const getContest = ({
    name,
    status,
    region,
    visibility_status,
    category,
    sortBy,
    limit,
    page,
}: FiltersData): ContestActionType => ({
    type: ContestActionTypes.CONTEST_LIST,
    payload: {
        name,
        status,
        region,
        visibility_status,
        category,
        sortBy,
        limit,
        page,
    },
});

export const showContest = (id: string): ContestActionType => ({
    type: ContestActionTypes.SHOW_CONTEST,
    payload: { id },
});

export const deleteContest = (id: string): ContestActionType => ({
    type: ContestActionTypes.DELETE_CONTEST,
    payload: { id },
});

export const updateContestStatus = (
    id: string,
    status: string,
): ContestActionType => ({
    type: ContestActionTypes.UPDATE_CONTEST_STATUS,
    payload: {
        id,
        status,
    },
});
export const updateContest = ({
    name,
    id,
    contestImage,
    joining_fee,
    duration,
    updateImage,
    schedules,
    lifeLineCharge,
    startDate,
    endDate,
    resultDate,
    winCoins,
}: ContestUpdateData
): ContestActionType => ({
    type: ContestActionTypes.UPDATE_CONTEST,
    payload: {
        name,
        id,
        contestImage,
        joining_fee,
        duration,
        schedules,
        lifeLineCharge,
        updateImage,
        startDate,
        endDate,
        resultDate,
        winCoins,
    },
});





