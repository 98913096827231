import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';

// helpers
import {
    predictions as predictionsApi,
    addPrediction as addPredictionApi,
    updatePrediction as updatePredictionApi,
    updatePredictionStatus as updatePredictionStatusApi,
    deletePrediction as deletePredictionApi,
    showPrediction as showPredictionApi,
    updatePredictionQuestionAnswer as updatePredictionQuestionAnswerApi,
} from '../../helpers';

// actions
import { predictionApiResponseSuccess, predictionApiResponseError } from './actions';

// constants
import { PredictionActionTypes } from './constants';

interface PredictionData {
    payload: {
        id: string,
        name: string,
        region: string,
        predictionImage: string,
        category: string,
        joining_fee: number,
        duration: number,
        marking: number,
        negative_marking: number,
        pool_size: number,
        prediction_question_count: number,
        questions: Array<string>,
        updateImage: string,
        status: string,
        sortBy: string,
        page: number,
        limit: number,
        schedules: Array<any>,
        startDate: Date,
        endDate: Date,
        resultDate: Date,
        firstContenderTitle: string,
        secondContenderTitle: string,
        firstContenderImage: string,
        secondContenderImage: string,
        winnerAmount: number,
        correctOption: string,
        updateFirstContenderImage: boolean,
        updateSecondContenderImage: boolean,
    };
    type: string;
}

/**
 * Prediction list
 */
function* predictionList({ payload: {
    name,
    status,
    region,
    category,
    sortBy,
    limit,
    page
} }: PredictionData): SagaIterator {
    try {

        const response = yield call(predictionsApi, {
            name,
            status,
            region,
            category,
            sortBy,
            limit,
            page
        });
        yield put(predictionApiResponseSuccess(
            PredictionActionTypes.PREDICTION_LIST,
            response.data.results,
            response.data.page,
            response.data.limit,
            response.data.totalPages,
            response.data.totalResults,
            response.data.categories,
        ));
    } catch (error: any) {
        yield put(predictionApiResponseError(PredictionActionTypes.PREDICTION_LIST, error));

    }
}

/**
 * Add Prediction
 */
function* addPrediction({ payload: {
    name,
    region,
    category,
    joining_fee,
    marking,
    negative_marking,
    prediction_question_count,
    questions,
    schedules,
    startDate,
    endDate,
    resultDate,
    firstContenderTitle,
    secondContenderTitle,
    firstContenderImage,
    secondContenderImage,
    winnerAmount,
} }: PredictionData): SagaIterator {
    try {

        const response = yield call(addPredictionApi, {
            name,
            region,
            category,
            joining_fee,
            marking,
            negative_marking,
            prediction_question_count,
            questions,
            schedules,
            startDate,
            endDate,
            resultDate,
            firstContenderTitle,
            secondContenderTitle,
            firstContenderImage,
            secondContenderImage,
            winnerAmount,
        });

        yield put(predictionApiResponseSuccess(
            PredictionActionTypes.ADD_PREDICTION,
            response.data.data
        ));
    } catch (error: any) {
        yield put(predictionApiResponseError(PredictionActionTypes.ADD_PREDICTION, error));

    }
}

/**
 * Update Prediction
 */
function* updatePrediction({ payload: {
    id,
    name,
    joining_fee,
    schedules,
    startDate,
    endDate,
    resultDate,
    firstContenderTitle,
    secondContenderTitle,
    firstContenderImage,
    secondContenderImage,
    winnerAmount,
    updateFirstContenderImage,
    updateSecondContenderImage,

} }: PredictionData): SagaIterator {
    try {

        const response = yield call(updatePredictionApi, {
            name,
            joining_fee,
            schedules,
            startDate,
            endDate,
            resultDate,
            firstContenderTitle,
            secondContenderTitle,
            firstContenderImage,
            secondContenderImage,
            winnerAmount,
            updateFirstContenderImage,
            updateSecondContenderImage,
        }, id);

        yield put(predictionApiResponseSuccess(
            PredictionActionTypes.UPDATE_PREDICTION,
            response.data.data
        ));
    } catch (error: any) {
        yield put(predictionApiResponseError(PredictionActionTypes.UPDATE_PREDICTION, error));

    }
}
/**
 * Update Prediction
 */
function* updatePredictionStatus({ payload: {
    id,
    status,

} }: PredictionData): SagaIterator {
    try {

        const response = yield call(updatePredictionStatusApi, {
            status,
        }, id);

        yield put(predictionApiResponseSuccess(
            PredictionActionTypes.UPDATE_PREDICTION_STATUS,
            response.data.data
        ));
    } catch (error: any) {
        yield put(predictionApiResponseError(PredictionActionTypes.UPDATE_PREDICTION_STATUS, error));

    }
}
/**
 * Show Prediction
 */
function* showPrediction({ payload: {
    id

} }: PredictionData): SagaIterator {
    try {

        const response = yield call(showPredictionApi, id);
        yield put(predictionApiResponseSuccess(
            PredictionActionTypes.SHOW_PREDICTION,
            response.data.data,
            1,
            10,
            1,
            1,
            response.data.categories,
        ));
    } catch (error: any) {
        yield put(predictionApiResponseError(PredictionActionTypes.SHOW_PREDICTION, error));

    }
}
/**
 * Delete Prediction
 */
function* deletePrediction({ payload: { id } }: PredictionData): SagaIterator {
    try {

        yield call(deletePredictionApi, id);
        yield put(predictionApiResponseSuccess(
            PredictionActionTypes.DELETE_PREDICTION,
            id
        ));
    } catch (error: any) {
        yield put(predictionApiResponseError(PredictionActionTypes.DELETE_PREDICTION, error));

    }
}
/*
 * Update  Prediction Answer
 */
function* updateAnswer({ payload: { id, correctOption } }: PredictionData): SagaIterator {
    try {

        const response = yield call(updatePredictionQuestionAnswerApi, { correctOption }, id);
        yield put(predictionApiResponseSuccess(
            PredictionActionTypes.UPDATE_ANSWER,
            response.data.data,
        ));
    } catch (error: any) {
        yield put(predictionApiResponseError(PredictionActionTypes.UPDATE_ANSWER, error));

    }
}




export function* watchPredictionList() {
    yield takeEvery(PredictionActionTypes.PREDICTION_LIST, predictionList);
}

export function* watchAddPrediction() {
    yield takeEvery(PredictionActionTypes.ADD_PREDICTION, addPrediction);
}

export function* watchShowPrediction() {
    yield takeEvery(PredictionActionTypes.SHOW_PREDICTION, showPrediction);
}

export function* watchUpdatePrediction() {
    yield takeEvery(PredictionActionTypes.UPDATE_PREDICTION, updatePrediction);
}
export function* watchUpdatePredictionStatus() {
    yield takeEvery(PredictionActionTypes.UPDATE_PREDICTION_STATUS, updatePredictionStatus);
}

export function* watchUpdateAnswer() {
    yield takeEvery(PredictionActionTypes.UPDATE_ANSWER, updateAnswer);
}
export function* watchDeletePrediction() {
    yield takeEvery(PredictionActionTypes.DELETE_PREDICTION, deletePrediction);
}

function* predictionSaga() {
    yield all([
        fork(watchPredictionList),
        fork(watchAddPrediction),
        fork(watchShowPrediction),
        fork(watchUpdatePrediction),
        fork(watchUpdatePredictionStatus),
        fork(watchUpdateAnswer),
        fork(watchDeletePrediction)
    ]);
}

export default predictionSaga;
