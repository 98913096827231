import { combineReducers } from 'redux';

import Auth from './auth/reducers';
import Layout from './layout/reducers';
import Category from './category/reducers';
import QuizQuestion from './quizQuestion/reducers';
import Quiz from './quiz/reducers';
import ContestQuestion from './contestQuestion/reducers';
import Contest from './contest/reducers';
import PredictionQuestion from './predictionQuestion/reducers';
import Prediction from './prediction/reducers';
import FunFactQuestion from './funFactQuestion/reducers';
import User from './user/reducers';
import ReportedIssue from './reportedIssue/reducers';
import ReportedQuestion from './reportedQuestion/reducers';
import Dashboard from './dashboard/reducers';
import WinnerCoinRank from './winnerCoinRank/reducers';

export default combineReducers({
    Auth,
    Layout,
    Category,
    QuizQuestion,
    Quiz,
    ContestQuestion,
    Contest,
    PredictionQuestion,
    Prediction,
    FunFactQuestion,
    User,
    ReportedIssue,
    ReportedQuestion,
    Dashboard,
    WinnerCoinRank,
});
