export enum CategoryActionTypes {
    API_RESPONSE_SUCCESS = '@@category/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR = '@@category/API_RESPONSE_ERROR',

    CATEGORY_LIST = '@@category/CATEGORY_LIST',
    ADD_CATEGORY = '@@category/ADD_CATEGORY',
    SHOW_CATEGORY = '@@category/SHOW_CATEGORY',
    UPDATE_CATEGORY = '@@category/UPDATE_CATEGORY',
    DELETE_CATEGORY = '@@category/DELETE_CATEGORY',
}
