export enum ContestQuestionActionTypes {
    API_RESPONSE_SUCCESS = '@@contestQuestion/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR = '@@contestQuestion/API_RESPONSE_ERROR',

    CONTEST_QUESTION_LIST = '@@contestQuestion/CONTEST_QUESTION_LIST',
    ADD_CONTEST_QUESTION = '@@contestQuestion/ADD_CONTEST_QUESTION',
    SHOW_CONTEST_QUESTION = '@@contestQuestion/SHOW_CONTEST_QUESTION',
    UPDATE_CONTEST_QUESTION = '@@contestQuestion/UPDATE_CONTEST_QUESTION',
    DELETE_CONTEST_QUESTION = '@@contestQuestion/DELETE_CONTEST_QUESTION',
    IMPORT_CONTEST_QUESTION = '@@contestQuestion/IMPORT_CONTEST_QUESTION',
}
