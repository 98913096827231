export enum QuizQuestionActionTypes {
    API_RESPONSE_SUCCESS = '@@quizQuestion/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR = '@@quizQuestion/API_RESPONSE_ERROR',

    QUIZ_QUESTION_LIST = '@@quizQuestion/QUIZ_QUESTION_LIST',
    ADD_QUIZ_QUESTION = '@@quizQuestion/ADD_QUIZ_QUESTION',
    SHOW_QUIZ_QUESTION = '@@quizQuestion/SHOW_QUIZ_QUESTION',
    UPDATE_QUIZ_QUESTION = '@@quizQuestion/UPDATE_QUIZ_QUESTION',
    DELETE_QUIZ_QUESTION = '@@quizQuestion/DELETE_QUIZ_QUESTION',
    IMPORT_QUIZ_QUESTION = '@@quizQuestion/IMPORT_QUIZ_QUESTION',
}
