// constants
import config from '../../config';
import { PredictionQuestionActionTypes } from './constants';

const INIT_STATE = {
    predictionQuestions: [],
    page: 1,
    limit: 50,
    totalPages: 0,
    totalResults: 0,
    predictionQuestionFile: '',
    loading: false,
    hasErrors: false,
    submitted: false,
    uploadedQuestions: false,
};

interface PredictionQuestionActionType {
    type:
    | PredictionQuestionActionTypes.API_RESPONSE_SUCCESS
    | PredictionQuestionActionTypes.API_RESPONSE_ERROR
    | PredictionQuestionActionTypes.PREDICTION_QUESTION_LIST
    | PredictionQuestionActionTypes.ADD_PREDICTION_QUESTION
    | PredictionQuestionActionTypes.SHOW_PREDICTION_QUESTION
    | PredictionQuestionActionTypes.UPDATE_PREDICTION_QUESTION

    payload: {
        actionType?: string;
        data?: any;
        page?: number;
        limit?: number;
        totalPages?: number;
        totalResults?: number;
        categories?: any;
        predictionQuestionFile?: string;
        error?: any;
        validationError?: { error: string; key: string; }[];
        hasErrors: boolean,
        submitted: boolean,
        uploadedQuestions: boolean,
    };
}

interface State {
    predictionQuestions: any;
    page: number;
    limit: number;
    totalPages: number;
    totalResults: number;
    predictionQuestionFile: string;
    
}

const PredictionQuestion = (state: State = INIT_STATE, action: PredictionQuestionActionType): any => {
    switch (action.type) {
        case PredictionQuestionActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case PredictionQuestionActionTypes.PREDICTION_QUESTION_LIST: {
                    return {
                        ...state,
                        predictionQuestions: action.payload.data,
                        page: action.payload.page,
                        limit: action.payload.limit,
                        totalPages: action.payload.totalPages,
                        totalResults: action.payload.totalResults,
                        categories: action.payload.categories,
                        loading: true,
                        submitted: false,
                        uploadedQuestions: false,
                    };
                }
                case PredictionQuestionActionTypes.ADD_PREDICTION_QUESTION: {
                    return {
                        ...state,
                        predictionQuestions: [...state.predictionQuestions, action.payload.data],
                        totalResults: (action.payload.totalResults ? (action.payload.totalResults + 1) : 0),
                        hasErrors: false,
                        loading: true,
                        submitted: true,
                        uploadedQuestions: false,
                    };
                }
                case PredictionQuestionActionTypes.UPDATE_PREDICTION_QUESTION: {
                    const updatedPredictionQuestion = action.payload.data;
                    const allPredictionQuestions = state.predictionQuestions;
                    const updatedPredictionQuestions = allPredictionQuestions.map((qq: any) => qq.id === updatedPredictionQuestion.id ? updatedPredictionQuestion : qq);
                    return {
                        ...state,
                        predictionQuestions: updatedPredictionQuestions,
                        hasErrors: false,
                        loading: true,
                        submitted: true,
                        uploadedQuestions: false,
                    };
                }
                case PredictionQuestionActionTypes.DELETE_PREDICTION_QUESTION: {
                    const deletedId = action.payload.data;
                    const allPredictionQuestions = state.predictionQuestions;
                    const updatedPredictionQuestions = allPredictionQuestions.filter((cat: any) => cat.id !== deletedId);
                    return {
                        ...state,
                        predictionQuestions: updatedPredictionQuestions,
                        hasErrors: false,
                        loading: true,
                        submitted: false,
                        uploadedQuestions: false,
                    };
                }
                case PredictionQuestionActionTypes.IMPORT_PREDICTION_QUESTION: {
                    const fileData = action.payload.data;
                    var FileSaver = require('file-saver');
                    const file = `${config.EXCEL_URL}/${fileData}`
                    FileSaver.saveAs(file, "uploadedQuestion.xlsx", { autoBom: true });
                    return {
                        ...state,
                        hasErrors: false,
                        loading: true,
                        submitted: false,
                        uploadedQuestions: true,
                    };
                }
                default:
                    return { ...state };
            }

        case PredictionQuestionActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case PredictionQuestionActionTypes.PREDICTION_QUESTION_LIST: {
                    return {
                        ...state,
                        error: action.payload.error,
                        hasErrors: true,
                        loading: true,
                        submitted: false,
                    };
                }
                case PredictionQuestionActionTypes.ADD_PREDICTION_QUESTION: {
                    return {
                        ...state,
                        error: action.payload.error,
                        validationError: action.payload.error,
                        hasErrors: true,
                        loading: true,
                        submitted: true,
                    };
                }
                case PredictionQuestionActionTypes.UPDATE_PREDICTION_QUESTION: {
                    return {
                        ...state,
                        error: action.payload.error,
                        validationError: action.payload.error,
                        hasErrors: true,
                        loading: true,
                        submitted: true,
                    };
                }
                case PredictionQuestionActionTypes.DELETE_PREDICTION_QUESTION: {
                    return {
                        ...state,
                        error: action.payload.error,
                        validationError: action.payload.error,
                        hasErrors: true,
                        loading: true,
                        submitted: true,
                    };
                }
                case PredictionQuestionActionTypes.IMPORT_PREDICTION_QUESTION: {
                    return {
                        ...state,
                        error: action.payload.error,
                        validationError: action.payload.error,
                        hasErrors: true,
                        loading: true,
                        submitted: true,
                        uploadedQuestions: true,
                    };
                }
                default:
                    return { ...state };
            }
        default:
            return { ...state };
    }
};

export default PredictionQuestion;
