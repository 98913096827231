// constants
import { PredictionActionTypes } from './constants';

export interface PredictionActionType {
    type:
    | PredictionActionTypes.API_RESPONSE_SUCCESS
    | PredictionActionTypes.API_RESPONSE_ERROR
    | PredictionActionTypes.PREDICTION_LIST
    | PredictionActionTypes.ADD_PREDICTION
    | PredictionActionTypes.UPDATE_PREDICTION
    | PredictionActionTypes.UPDATE_PREDICTION_STATUS
    | PredictionActionTypes.SHOW_PREDICTION
    | PredictionActionTypes.DELETE_PREDICTION
    | PredictionActionTypes.UPDATE_ANSWER
    payload: {} | string;
}


// common success
export const predictionApiResponseSuccess = (actionType: string, data: any, page: number = 0, limit: number = 0, totalPages: number = 0, totalResults: number = 0, categories: any = []): PredictionActionType => ({
    type: PredictionActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data, page, limit, totalPages, totalResults, categories },
});
// common error
export const predictionApiResponseError = (actionType: string, error: string): PredictionActionType => ({
    type: PredictionActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

interface PredictionData {
    id: string,
    name: string,
    region: string,
    category: string,
    joining_fee: number,
    marking: number,
    negative_marking: number,
    status: string,
    prediction_question_count: number,
    questions: Array<string>,
    schedules: Array<any>,
    startDate: Date,
    endDate: Date,
    resultDate: Date,
    firstContenderTitle: string,
    secondContenderTitle: string,
    firstContenderImage: string,
    secondContenderImage: string,
    winnerAmount: any
    updateFirstContenderImage: any,
    updateSecondContenderImage: any,
}

export const addPrediction = ({
    name,
    region,
    category,
    joining_fee,
    marking,
    negative_marking,
    prediction_question_count,
    questions,
    schedules,
    startDate,
    endDate,
    resultDate,
    firstContenderTitle,
    secondContenderTitle,
    firstContenderImage,
    secondContenderImage,
    winnerAmount,

}: PredictionData
): PredictionActionType => ({
    type: PredictionActionTypes.ADD_PREDICTION,
    payload: {
        name,
        region,
        category,
        joining_fee,
        marking,
        negative_marking,
        prediction_question_count,
        questions,
        schedules,
        startDate,
        endDate,
        resultDate,
        firstContenderTitle,
        secondContenderTitle,
        firstContenderImage,
        secondContenderImage,
        winnerAmount,
    },
});

interface FiltersData {
    name: string,
    status: string,
    region: string,
    category: any,
    sortBy: string,
    limit: number,
    page: number,
};

export const getPrediction = ({
    name,
    status,
    region,
    category,
    sortBy,
    limit,
    page,
}: FiltersData): PredictionActionType => ({
    type: PredictionActionTypes.PREDICTION_LIST,
    payload: {
        name,
        status,
        region,
        category,
        sortBy,
        limit,
        page,
    },
});

export const showPrediction = (id: string): PredictionActionType => ({
    type: PredictionActionTypes.SHOW_PREDICTION,
    payload: { id },
});

export const deletePrediction = (id: string): PredictionActionType => ({
    type: PredictionActionTypes.DELETE_PREDICTION,
    payload: { id },
});

export const updatePrediction = ({
    id,
    name,
    region,
    category,
    joining_fee,
    marking,
    negative_marking,
    status,
    prediction_question_count,
    questions,
    schedules,
    startDate,
    endDate,
    resultDate,
    firstContenderTitle,
    secondContenderTitle,
    firstContenderImage,
    secondContenderImage,
    winnerAmount,
    updateFirstContenderImage,
    updateSecondContenderImage
}: PredictionData): PredictionActionType => ({
    type: PredictionActionTypes.UPDATE_PREDICTION,
    payload: {
        id,
        name,
        joining_fee,
        schedules,
        startDate,
        endDate,
        resultDate,
        firstContenderTitle,
        secondContenderTitle,
        firstContenderImage,
        secondContenderImage,
        winnerAmount,
        updateFirstContenderImage,
        updateSecondContenderImage,
    },
});
export const updatePredictionStatus = (
    id: string,
    status: string,
): PredictionActionType => ({
    type: PredictionActionTypes.UPDATE_PREDICTION_STATUS,
    payload: {
        id,
        status,
    },
});
export const updateAnswer = (
    id: string,
    correctOption: string,
): PredictionActionType => ({
    type: PredictionActionTypes.UPDATE_ANSWER,
    payload: {
        id,
        correctOption,
    },
});





