import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';

// helpers
import {
    winnerCoinRanks as winnerCoinRanksApi,
    addWinnerCoinRank as addWinnerCoinRankApi,
    updateWinnerCoinRank as updateWinnerCoinRankApi,
    deleteWinnerCoinRank as deleteWinnerCoinRankApi,
} from '../../helpers';

// actions
import { winnerCoinRankApiResponseSuccess, winnerCoinRankApiResponseError } from './actions';

// constants
import { WinnerCoinRankActionTypes } from './constants';

interface WinnerCoinRankData {
    payload: {
        id: string,
        name: string,
        status: string,
        sortBy: string,
        page: number,
        limit: number,
        rankWiseCoins: Array<number>,

    };
    type: string;
}

/**
 * WinnerCoinRank list
 */
function* winnerCoinRankList({ payload: {
    name,
    status,
    sortBy,
    limit,
    page
} }: WinnerCoinRankData): SagaIterator {
    try {

        const response = yield call(winnerCoinRanksApi, {
            name,
            status,
            sortBy,
            limit,
            page
        });
        yield put(winnerCoinRankApiResponseSuccess(
            WinnerCoinRankActionTypes.WINNER_COIN_RANK_LIST,
            response.data.results,
            response.data.page,
            response.data.limit,
            response.data.totalPages,
            response.data.totalResults,
        ));
    } catch (error: any) {
        yield put(winnerCoinRankApiResponseError(WinnerCoinRankActionTypes.WINNER_COIN_RANK_LIST, error));

    }
}

/**
 * Add WinnerCoinRank
 */
function* addWinnerCoinRank({ payload: {
    name,
    rankWiseCoins,
} }: WinnerCoinRankData): SagaIterator {
    try {

        const response = yield call(addWinnerCoinRankApi, {
            name,
            rankWiseCoins,

        });

        yield put(winnerCoinRankApiResponseSuccess(
            WinnerCoinRankActionTypes.ADD_WINNER_COIN_RANK,
            response.data.data
        ));
    } catch (error: any) {
        yield put(winnerCoinRankApiResponseError(WinnerCoinRankActionTypes.ADD_WINNER_COIN_RANK, error));

    }
}

/**
 * Update WinnerCoinRank
 */
function* updateWinnerCoinRank({ payload: {
    id,
    name,
    rankWiseCoins,
    status,

} }: WinnerCoinRankData): SagaIterator {
    try {

        const response = yield call(updateWinnerCoinRankApi, {
            name,
            rankWiseCoins,
            status,
        }, id);

        yield put(winnerCoinRankApiResponseSuccess(
            WinnerCoinRankActionTypes.UPDATE_WINNER_COIN_RANK,
            response.data.data
        ));
    } catch (error: any) {
        yield put(winnerCoinRankApiResponseError(WinnerCoinRankActionTypes.UPDATE_WINNER_COIN_RANK, error));

    }
}
/**
 * Delete WinnerCoinRank
 */
function* deleteWinnerCoinRank({ payload: { id } }: WinnerCoinRankData): SagaIterator {
    try {
        yield call(deleteWinnerCoinRankApi, id);
        yield put(winnerCoinRankApiResponseSuccess(
            WinnerCoinRankActionTypes.DELETE_WINNER_COIN_RANK,
            id
        ));
    } catch (error: any) {
        yield put(winnerCoinRankApiResponseError(WinnerCoinRankActionTypes.DELETE_WINNER_COIN_RANK, error));

    }
}




export function* watchWinnerCoinRankList() {
    yield takeEvery(WinnerCoinRankActionTypes.WINNER_COIN_RANK_LIST, winnerCoinRankList);
}

export function* watchAddWinnerCoinRank() {
    yield takeEvery(WinnerCoinRankActionTypes.ADD_WINNER_COIN_RANK, addWinnerCoinRank);
}

export function* watchUpdateWinnerCoinRank() {
    yield takeEvery(WinnerCoinRankActionTypes.UPDATE_WINNER_COIN_RANK, updateWinnerCoinRank);
}

export function* watchDeleteWinnerCoinRank() {
    yield takeEvery(WinnerCoinRankActionTypes.DELETE_WINNER_COIN_RANK, deleteWinnerCoinRank);
}




function* winnerCoinRankSaga() {
    yield all([fork(watchWinnerCoinRankList), fork(watchAddWinnerCoinRank), fork(watchUpdateWinnerCoinRank), fork(watchDeleteWinnerCoinRank)]);
}

export default winnerCoinRankSaga;
