export enum PredictionQuestionActionTypes {
    API_RESPONSE_SUCCESS = '@@predictionQuestion/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR = '@@predictionQuestion/API_RESPONSE_ERROR',

    PREDICTION_QUESTION_LIST = '@@predictionQuestion/PREDICTION_QUESTION_LIST',
    ADD_PREDICTION_QUESTION = '@@predictionQuestion/ADD_PREDICTION_QUESTION',
    SHOW_PREDICTION_QUESTION = '@@predictionQuestion/SHOW_PREDICTION_QUESTION',
    UPDATE_PREDICTION_QUESTION = '@@predictionQuestion/UPDATE_PREDICTION_QUESTION',
    DELETE_PREDICTION_QUESTION = '@@predictionQuestion/DELETE_PREDICTION_QUESTION',
    IMPORT_PREDICTION_QUESTION = '@@predictionQuestion/IMPORT_PREDICTION_QUESTION',
}
