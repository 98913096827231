// constants
import { ReportedIssueActionTypes } from './constants';

export interface ReportedIssueActionType {
    type:
    | ReportedIssueActionTypes.API_RESPONSE_SUCCESS
    | ReportedIssueActionTypes.API_RESPONSE_ERROR
    | ReportedIssueActionTypes.REPORTED_ISSUE_LIST

    payload: {} | string;
}


// common success
export const reportedIssueApiResponseSuccess = (actionType: string, data: any, page: number = 0, limit: number = 0, totalPages: number = 0, totalResults: number = 0): ReportedIssueActionType => ({
    type: ReportedIssueActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data, page, limit, totalPages, totalResults },
});
// common error
export const reportedIssueApiResponseError = (actionType: string, error: string): ReportedIssueActionType => ({
    type: ReportedIssueActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});




interface FiltersData {
    userId: string,
    issue: string,
    sortBy: string,
    limit: number,
    page: number,
};

export const getReportedIssue = ({
    userId,
    issue,
    sortBy,
    limit,
    page,
}: FiltersData): ReportedIssueActionType => ({
    type: ReportedIssueActionTypes.REPORTED_ISSUE_LIST,
    payload: {
        userId,
        issue,
        sortBy,
        limit,
        page,
    },
});






