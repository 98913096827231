import { APICore } from './apiCore';
const api = new APICore();

// user
// eslint-disable-next-line no-empty-pattern


function dashboard(params: {}) {
    const baseUrl = `/dashboard`;
    return api.get(`${baseUrl}`, params);

}
function getUserGraph(params: { range: string, userType: string }) {
    const baseUrl = `/dashboard/userGraph`;
    return api.get(`${baseUrl}`, params);

}





export { dashboard, getUserGraph };
