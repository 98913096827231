// constants
import { ReportedQuestionActionTypes } from './constants';

export interface ReportedQuestionActionType {
    type:
    | ReportedQuestionActionTypes.API_RESPONSE_SUCCESS
    | ReportedQuestionActionTypes.API_RESPONSE_ERROR
    | ReportedQuestionActionTypes.REPORTED_QUESTION_LIST

    payload: {} | string;
}


// common success
export const reportedQuestionApiResponseSuccess = (actionType: string, data: any, page: number = 0, limit: number = 0, totalPages: number = 0, totalResults: number = 0): ReportedQuestionActionType => ({
    type: ReportedQuestionActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data, page, limit, totalPages, totalResults },
});
// common error
export const reportedQuestionApiResponseError = (actionType: string, error: string): ReportedQuestionActionType => ({
    type: ReportedQuestionActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});




interface FiltersData {
    userId: string,
    questionId: string,
    questionType: string,
    sortBy: string,
    limit: number,
    page: number,
};

export const getReportedQuestion = ({
    userId,
    questionId,
    questionType,
    sortBy,
    limit,
    page,
}: FiltersData): ReportedQuestionActionType => ({
    type: ReportedQuestionActionTypes.REPORTED_QUESTION_LIST,
    payload: {
        userId,
        questionId,
        questionType,
        sortBy,
        limit,
        page,
    },
});






