export enum ContestActionTypes {
    API_RESPONSE_SUCCESS = '@@contest/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR = '@@contest/API_RESPONSE_ERROR',

    CONTEST_LIST = '@@contest/CONTEST_LIST',
    ADD_CONTEST = '@@contest/ADD_CONTEST',
    SHOW_CONTEST = '@@contest/SHOW_CONTEST',
    UPDATE_CONTEST = '@@contest/UPDATE_CONTEST',
    UPDATE_CONTEST_STATUS = '@@contest/UPDATE_CONTEST_STATUS',
    DELETE_CONTEST = '@@contest/DELETE_CONTEST',
}
