import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';

// helpers
import {
    contests as contestsApi,
    addContest as addContestApi,
    updateContestStatus as updateContestStatusApi,
    updateContest as updateContestApi,
    showContest as showContestApi,
    deleteContest as deleteContestApi,
} from '../../helpers';

// actions
import { contestApiResponseSuccess, contestApiResponseError } from './actions';

// constants
import { ContestActionTypes } from './constants';

interface ContestData {
    payload: {
        id: string,
        name: string,
        region: string,
        visibility_status: string,
        contestImage: string,
        category: string,
        joining_fee: number,
        duration: any,
        marking: number,
        negative_marking: number,
        pool_size: number,
        contest_question_count: number,
        questions: Array<string>,
        updateImage: any,
        status: string,
        sortBy: string,
        page: number,
        limit: number,
        schedules: Array<any>,
        winnerAmount: Array<number>,
        lifeLineCharge: number,
        startDate: Date,
        endDate: Date,
        startTime: Date,
        endTime: Date,
        resultDate: Date,
        winCoins: Number,
    };
    type: string;
}

/**
 * Contest list
 */
function* contestList({ payload: {
    name,
    status,
    region,
    visibility_status,
    category,
    sortBy,
    limit,
    page
} }: ContestData): SagaIterator {
    try {

        const response = yield call(contestsApi, {
            name,
            status,
            visibility_status,
            region,
            category,
            sortBy,
            limit,
            page
        });
        yield put(contestApiResponseSuccess(
            ContestActionTypes.CONTEST_LIST,
            response.data.results,
            response.data.page,
            response.data.limit,
            response.data.totalPages,
            response.data.totalResults,
            response.data.categories,
        ));
    } catch (error: any) {
        yield put(contestApiResponseError(ContestActionTypes.CONTEST_LIST, error));

    }
}

/**
 * Add Contest
 */
function* addContest({ payload: {
    name,
    region,
    contestImage,
    category,
    joining_fee,
    duration,
    marking,
    negative_marking,
    pool_size,
    contest_question_count,
    questions,
    schedules,
    winnerAmount,
    lifeLineCharge,
    startDate,
    endDate,
    startTime,
    endTime,
    // resultDate,
    winCoins,
} }: ContestData): SagaIterator {
    try {

        const response = yield call(addContestApi, {
            name,
            region,
            contestImage,
            category,
            joining_fee,
            duration,
            marking,
            negative_marking,
            pool_size,
            contest_question_count,
            questions,
            schedules,
            winnerAmount,
            lifeLineCharge,
            startDate,
            endDate,
            startTime,
            endTime,
            // resultDate,
            winCoins,
        });

        yield put(contestApiResponseSuccess(
            ContestActionTypes.ADD_CONTEST,
            response.data.data
        ));
    } catch (error: any) {
        yield put(contestApiResponseError(ContestActionTypes.ADD_CONTEST, error));

    }
}

/**
 * Update Contest
 */
function* updateContest({ payload: {
    id,
    name,
    contestImage,
    joining_fee,
    duration,
    schedules,
    lifeLineCharge,
    startDate,
    updateImage,
    endDate,
    resultDate,
    winCoins,

} }: ContestData): SagaIterator {
    try {

        const response = yield call(updateContestApi, {
            name,
            contestImage,
            joining_fee,
            duration,
            schedules,
            lifeLineCharge,
            updateImage,
            startDate,
            endDate,
            resultDate,
            winCoins,
        }, id);

        yield put(contestApiResponseSuccess(
            ContestActionTypes.UPDATE_CONTEST,
            response.data.data
        ));
    } catch (error: any) {
        yield put(contestApiResponseError(ContestActionTypes.UPDATE_CONTEST, error));

    }
}
/**
 * Update Contest Status
 */
function* updateContestStatus({ payload: {
    id,
    status,

} }: ContestData): SagaIterator {
    try {

        const response = yield call(updateContestStatusApi, {
            status,
        }, id);

        yield put(contestApiResponseSuccess(
            ContestActionTypes.UPDATE_CONTEST,
            response.data.data
        ));
    } catch (error: any) {
        yield put(contestApiResponseError(ContestActionTypes.UPDATE_CONTEST, error));

    }
}
/**
 * Show Contest
 */
function* showContest({ payload: {
    id

} }: ContestData): SagaIterator {
    try {

        const response = yield call(showContestApi, id);
        yield put(contestApiResponseSuccess(
            ContestActionTypes.SHOW_CONTEST,
            response.data.data,
            1,
            10,
            1,
            1,
            response.data.categories,
        ));
    } catch (error: any) {
        yield put(contestApiResponseError(ContestActionTypes.SHOW_CONTEST, error));

    }
}
/**
 * Delete Contest
 */
function* deleteContest({ payload: { id } }: ContestData): SagaIterator {
    try {

        yield call(deleteContestApi, id);
        yield put(contestApiResponseSuccess(
            ContestActionTypes.DELETE_CONTEST,
            id
        ));
    } catch (error: any) {
        yield put(contestApiResponseError(ContestActionTypes.DELETE_CONTEST, error));

    }
}




export function* watchContestList() {
    yield takeEvery(ContestActionTypes.CONTEST_LIST, contestList);
}

export function* watchAddContest() {
    yield takeEvery(ContestActionTypes.ADD_CONTEST, addContest);
}
export function* watchShowContest() {
    yield takeEvery(ContestActionTypes.SHOW_CONTEST, showContest);
}

export function* watchUpdateContest() {
    yield takeEvery(ContestActionTypes.UPDATE_CONTEST, updateContest);
}
export function* watchUpdateContestStatus() {
    yield takeEvery(ContestActionTypes.UPDATE_CONTEST_STATUS, updateContestStatus);
}

export function* watchDeleteContest() {
    yield takeEvery(ContestActionTypes.DELETE_CONTEST, deleteContest);
}




function* contestSaga() {
    yield all([
        fork(watchContestList),
        fork(watchAddContest),
        fork(watchShowContest),
        fork(watchUpdateContest),
        fork(watchUpdateContestStatus),
        fork(watchDeleteContest)
    ]);
}

export default contestSaga;
