// constants
import { ContestActionTypes } from './constants';

const INIT_STATE = {
    contests: [],
    page: 1,
    limit: 50,
    totalPages: 0,
    contest: {},
    totalResults: 0,
    categories:[],
    loading: false,
    hasErrors: false,
    submitted: false,
};

interface ContestActionType {
    type:
    | ContestActionTypes.API_RESPONSE_SUCCESS
    | ContestActionTypes.API_RESPONSE_ERROR
    | ContestActionTypes.CONTEST_LIST
    | ContestActionTypes.ADD_CONTEST
    | ContestActionTypes.SHOW_CONTEST
    | ContestActionTypes.UPDATE_CONTEST

    payload: {
        actionType?: string;
        data?: any;
        page?: number;
        limit?: number;
        totalPages?: number;
        totalResults?: number;
        categories?: any;
        error?: any;
        validationError?: { error: string; key: string; }[];
        hasErrors: boolean,
        submitted: boolean,
    };
}

interface State {
    contests: any;
    contest: any;
    page: number;
    limit: number;
    totalPages: number;
    totalResults: number;
}

const Contest = (state: State = INIT_STATE, action: ContestActionType): any => {
    switch (action.type) {
        case ContestActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case ContestActionTypes.CONTEST_LIST: {
                    return {
                        ...state,
                        contests: action.payload.data,
                        page: action.payload.page,
                        limit: action.payload.limit,
                        totalPages: action.payload.totalPages,
                        totalResults: action.payload.totalResults,
                        categories: action.payload.categories,
                        loading: true,
                        submitted: false,
                    };
                }
                case ContestActionTypes.ADD_CONTEST: {
                    return {
                        ...state,
                        // contests: [...state.contests, action.payload.data],
                        // totalResults: (action.payload.totalResults ? (action.payload.totalResults + 1) : 0),
                        hasErrors: false,
                        loading: true,
                        submitted: true,
                    };
                }
                case ContestActionTypes.UPDATE_CONTEST: {
                    const updatedContest = action.payload.data;
                    const allContests = state.contests;
                    const updatedContests = allContests.map((qq: any) => qq.id === updatedContest.id ? updatedContest : qq);
                    return {
                        ...state,
                        contests: updatedContests,
                        hasErrors: false,
                        loading: true,
                        submitted: true,
                    };
                }
                case ContestActionTypes.SHOW_CONTEST: {
                    const showContest = action.payload.data;
                    return {
                        ...state,
                        contest: showContest,
                        categories: action.payload.categories,
                        hasErrors: false,
                        loading: true,
                        submitted: false,
                    };
                }
                case ContestActionTypes.UPDATE_CONTEST_STATUS: {
                    const updatedContest = action.payload.data;
                    const allContests = state.contests;
                    const updatedContests = allContests.map((qq: any) => qq.id === updatedContest.id ? updatedContest : qq);
                    return {
                        ...state,
                        contests: updatedContests,
                        hasErrors: false,
                        loading: true,
                        submitted: true,
                    };
                }
                case ContestActionTypes.DELETE_CONTEST: {
                    const deletedId = action.payload.data;
                    const allContests = state.contests;
                    const updatedContests = allContests.filter((cat: any) => cat.id !== deletedId);
                    return {
                        ...state,
                        contests: updatedContests,
                        hasErrors: false,
                        loading: true,
                        submitted: true,
                    };
                }
                default:
                    return { ...state };
            }

        case ContestActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case ContestActionTypes.CONTEST_LIST: {
                    return {
                        ...state,
                        error: action.payload.error,
                        hasErrors: true,
                        loading: true,
                        submitted: false,
                    };
                }
                case ContestActionTypes.ADD_CONTEST: {
                    return {
                        ...state,
                        error: action.payload.error,
                        validationError: action.payload.error,
                        hasErrors: true,
                        loading: true,
                        submitted: true,
                    };
                }
                case ContestActionTypes.UPDATE_CONTEST: {
                    return {
                        ...state,
                        error: action.payload.error,
                        validationError: action.payload.error,
                        hasErrors: true,
                        loading: true,
                        submitted: true,
                    };
                }
                case ContestActionTypes.SHOW_CONTEST: {
                    return {
                        ...state,
                        error: action.payload.error,
                        validationError: action.payload.error,
                        hasErrors: true,
                        loading: true,
                        submitted: false,
                    };
                }
                case ContestActionTypes.UPDATE_CONTEST_STATUS: {
                    return {
                        ...state,
                        error: action.payload.error,
                        validationError: action.payload.error,
                        hasErrors: true,
                        loading: true,
                        submitted: true,
                    };
                }
                default:
                    return { ...state };
            }
        default:
            return { ...state };
    }
};

export default Contest;
