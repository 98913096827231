import { APICore } from './apiCore';
const api = new APICore();

// quizQuestion
// eslint-disable-next-line no-empty-pattern
function quizQuestions(params: {
    question: string,
    regions: string,
    category: string,
    type: string,
    sortBy: string,
    limit: number,
    page: number;
}) {
    const baseUrl = `/quizQuestion`;
    return api.get(`${baseUrl}`, params);

}
function addQuizQuestion(params: { question: string, type: string, media: string, category: string, options: Array<{ option: string, selected: boolean }>, level: string, regions: Array<any> }) {
    const baseUrl = `/quizQuestion/`;
    return api.create(`${baseUrl}`, params);

}
function updateQuizQuestion(params: { question: string, type: string, media: string, category: string, options: Array<{ option: string, selected: boolean }>, level: string, regions: Array<any>, updateMedia: boolean }, id: string) {
    const baseUrl = `/quizQuestion/${id}`;
    return api.updatePatch(`${baseUrl}`, params);

}

function deleteQuizQuestion(id: string) {
    const baseUrl = `/quizQuestion/${id}`;
    return api.delete(`${baseUrl}`);

}

function uploadQuizQuestionFile(params: { file: File[] }) {
    const baseUrl = `/quizQuestion/uploadQuestionMedia`;
    return api.uploadFile(`${baseUrl}`, params);
}
function importQuizQuestions(params: {
    regions: string,
    category: string,
    file: File,
}) {
    const baseUrl = `/quizQuestion/importQuestions`;
    return api.createWithFile(`${baseUrl}`, params);
}



export { quizQuestions, addQuizQuestion, updateQuizQuestion, deleteQuizQuestion, uploadQuizQuestionFile, importQuizQuestions };
