// constants
import { FunFactQuestionActionTypes } from './constants';

export interface FunFactQuestionActionType {
    type:
    | FunFactQuestionActionTypes.API_RESPONSE_SUCCESS
    | FunFactQuestionActionTypes.API_RESPONSE_ERROR
    | FunFactQuestionActionTypes.FUNFACT_QUESTION_LIST
    | FunFactQuestionActionTypes.ADD_FUNFACT_QUESTION
    | FunFactQuestionActionTypes.UPDATE_FUNFACT_QUESTION
    | FunFactQuestionActionTypes.SHOW_FUNFACT_QUESTION
    | FunFactQuestionActionTypes.DELETE_FUNFACT_QUESTION
    | FunFactQuestionActionTypes.IMPORT_FUNFACT_QUESTION
    payload: {} | string;
}


// common success
export const funFactQuestionApiResponseSuccess = (actionType: string, data: any, page: number = 0, limit: number = 0, totalPages: number = 0, totalResults: number = 0): FunFactQuestionActionType => ({
    type: FunFactQuestionActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data, page, limit, totalPages, totalResults },
});
// common error
export const funFactQuestionApiResponseError = (actionType: string, error: string): FunFactQuestionActionType => ({
    type: FunFactQuestionActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const addFunFactQuestion = (question: string, options: Array<{ option: string, selected: boolean }>, funFact: string, regions: any): FunFactQuestionActionType => ({
    type: FunFactQuestionActionTypes.ADD_FUNFACT_QUESTION,
    payload: { question, options, funFact, regions },
});

interface ImportFromExcelData {
    regions: string;
    file: File;
};

interface FiltersData {
    question: string;
    sortBy: string;
    limit: number;
    page: number;
};
export const getFunFactQuestion = ({
    question,
    sortBy,
    limit,
    page,
}: FiltersData): FunFactQuestionActionType => ({
    type: FunFactQuestionActionTypes.FUNFACT_QUESTION_LIST,
    payload: {
        question,
        sortBy,
        limit,
        page,
    },
});

export const showFunFactQuestion = (id: string): FunFactQuestionActionType => ({
    type: FunFactQuestionActionTypes.SHOW_FUNFACT_QUESTION,
    payload: { id },
});

export const deleteFunFactQuestion = (id: string): FunFactQuestionActionType => ({
    type: FunFactQuestionActionTypes.DELETE_FUNFACT_QUESTION,
    payload: { id },
});

export const updateFunFactQuestion = (id: string, question: string, options: Array<{ option: string, selected: boolean }>, funFact: string, regions: any): FunFactQuestionActionType => ({
    type: FunFactQuestionActionTypes.UPDATE_FUNFACT_QUESTION,
    payload: { id, question, options, funFact, regions },
});

export const importFunFactQuestions = ({
    regions,
    file,
}: ImportFromExcelData): FunFactQuestionActionType => ({
    type: FunFactQuestionActionTypes.IMPORT_FUNFACT_QUESTION,
    payload: {
        regions,
        file,
    },
});





