export enum WinnerCoinRankActionTypes {
    API_RESPONSE_SUCCESS = '@@winnerCoinRank/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR = '@@winnerCoinRank/API_RESPONSE_ERROR',

    WINNER_COIN_RANK_LIST = '@@winnerCoinRank/WINNER_COIN_RANK_LIST',
    ADD_WINNER_COIN_RANK = '@@winnerCoinRank/ADD_WINNER_COIN_RANK',
    SHOW_WINNER_COIN_RANK = '@@winnerCoinRank/SHOW_WINNER_COIN_RANK',
    UPDATE_WINNER_COIN_RANK = '@@winnerCoinRank/UPDATE_WINNER_COIN_RANK',
    DELETE_WINNER_COIN_RANK = '@@winnerCoinRank/DELETE_WINNER_COIN_RANK',
}
