export enum PredictionActionTypes {
    API_RESPONSE_SUCCESS = '@@prediction/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR = '@@prediction/API_RESPONSE_ERROR',

    PREDICTION_LIST = '@@prediction/PREDICTION_LIST',
    ADD_PREDICTION = '@@prediction/ADD_PREDICTION',
    SHOW_PREDICTION = '@@prediction/SHOW_PREDICTION',
    UPDATE_PREDICTION = '@@prediction/UPDATE_PREDICTION',
    UPDATE_PREDICTION_STATUS = '@@prediction/UPDATE_PREDICTION_STATUS',
    DELETE_PREDICTION = '@@prediction/DELETE_PREDICTION',
    UPDATE_ANSWER = '@@prediction/UPDATE_ANSWER',
}
