import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';

// helpers
import {
    predictionQuestions as predictionQuestionsApi,
    addPredictionQuestion as addPredictionQuestionApi,
    updatePredictionQuestion as updatePredictionQuestionApi,
    deletePredictionQuestion as deletePredictionQuestionApi,
    importPredictionQuestions as importPredictionQuestionsApi,
} from '../../helpers';

// actions
import { predictionQuestionApiResponseSuccess, predictionQuestionApiResponseError } from './actions';

// constants
import { PredictionQuestionActionTypes } from './constants';

interface PredictionQuestionData {
    payload: {
        id: string;
        question: string;
        type: string;
        category: string;
        level: string;
        media: string;
        updateMedia: boolean;
        options: Array<{ option: string, selected: boolean }>;
        regions: any;
        sortBy: string;
        limit: number;
        correctOption: any;
        page: number;
        file: File;
    };
    type: string;
}



/**
 * PredictionQuestion list
 */
function* predictionQuestionList({ payload: { question, regions: string, category, sortBy, limit, page, type, correctOption } }: PredictionQuestionData): SagaIterator {
    try {

        const response = yield call(predictionQuestionsApi, { question, regions: string, category, sortBy, limit, page, type, correctOption });

        yield put(predictionQuestionApiResponseSuccess(
            PredictionQuestionActionTypes.PREDICTION_QUESTION_LIST,
            response.data.results,
            response.data.page,
            response.data.limit,
            response.data.totalPages,
            response.data.totalResults,
            response.data.categories,
        ));
    } catch (error: any) {
        yield put(predictionQuestionApiResponseError(PredictionQuestionActionTypes.PREDICTION_QUESTION_LIST, error));

    }
}

/**
 * Add PredictionQuestion
 */
function* addPredictionQuestion({ payload: { question, type, options, level, regions, category, media } }: PredictionQuestionData): SagaIterator {
    try {

        const response = yield call(addPredictionQuestionApi, { question, type, options, level, category, regions, media });

        yield put(predictionQuestionApiResponseSuccess(
            PredictionQuestionActionTypes.ADD_PREDICTION_QUESTION,
            response.data.data
        ));
    } catch (error: any) {
        yield put(predictionQuestionApiResponseError(PredictionQuestionActionTypes.ADD_PREDICTION_QUESTION, error));

    }
}

/**
 * Update PredictionQuestion
 */
function* updatePredictionQuestion({ payload: { id, question, type, category, options, level, regions, media, updateMedia } }: PredictionQuestionData): SagaIterator {
    try {

        const response = yield call(updatePredictionQuestionApi, { question, category, type, options, level, regions, media, updateMedia }, id);

        yield put(predictionQuestionApiResponseSuccess(
            PredictionQuestionActionTypes.UPDATE_PREDICTION_QUESTION,
            response.data.data
        ));
    } catch (error: any) {
        yield put(predictionQuestionApiResponseError(PredictionQuestionActionTypes.UPDATE_PREDICTION_QUESTION, error));

    }
}
/**
 * Delete PredictionQuestion
 */
function* deletePredictionQuestion({ payload: { id } }: PredictionQuestionData): SagaIterator {
    try {

        yield call(deletePredictionQuestionApi, id);
        yield put(predictionQuestionApiResponseSuccess(
            PredictionQuestionActionTypes.DELETE_PREDICTION_QUESTION,
            id
        ));
    } catch (error: any) {
        yield put(predictionQuestionApiResponseError(PredictionQuestionActionTypes.DELETE_PREDICTION_QUESTION, error));

    }
}
/**
 * Import Prediction Questions
 */
function* importPredictionQuestions({ payload: {
    regions,
    category,
    file,
} }: PredictionQuestionData): SagaIterator {
    try {

        const response = yield call(importPredictionQuestionsApi, {
            regions,
            category,
            file,
        });
        yield put(predictionQuestionApiResponseSuccess(
            PredictionQuestionActionTypes.IMPORT_PREDICTION_QUESTION,
            response.data.data
        ));
    } catch (error: any) {
        yield put(predictionQuestionApiResponseError(PredictionQuestionActionTypes.IMPORT_PREDICTION_QUESTION, error));
    }
}




export function* watchPredictionQuestionList() {
    yield takeEvery(PredictionQuestionActionTypes.PREDICTION_QUESTION_LIST, predictionQuestionList);
}

export function* watchAddPredictionQuestion() {
    yield takeEvery(PredictionQuestionActionTypes.ADD_PREDICTION_QUESTION, addPredictionQuestion);
}

export function* watchUpdatePredictionQuestion() {
    yield takeEvery(PredictionQuestionActionTypes.UPDATE_PREDICTION_QUESTION, updatePredictionQuestion);
}

export function* watchDeletePredictionQuestion() {
    yield takeEvery(PredictionQuestionActionTypes.DELETE_PREDICTION_QUESTION, deletePredictionQuestion);
}

export function* watchImportPredictionQuestions() {
    yield takeEvery(PredictionQuestionActionTypes.IMPORT_PREDICTION_QUESTION, importPredictionQuestions);
}




function* predictionQuestionSaga() {
    yield all([fork(watchPredictionQuestionList), fork(watchAddPredictionQuestion), fork(watchUpdatePredictionQuestion), fork(watchDeletePredictionQuestion), fork(watchImportPredictionQuestions)]);
}

export default predictionQuestionSaga;
