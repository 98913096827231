
const apiPrefix = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}`;
const config = {
    API_URL: process.env.REACT_APP_API_URL,
    EXCEL_URL: process.env.REACT_APP_API_URL,
    VERSION: process.env.REACT_APP_API_VERSION,
    CATEGORY_IMG_URL: `${process.env.REACT_APP_API_URL}/categoryImage`,
    QUESTION_IMG_URL: `${process.env.REACT_APP_API_URL}/quizQuestionMedia`,
    QUIZ_IMG_URL: `${process.env.REACT_APP_API_URL}/quizImage`,
    CONTEST_QUESTION_IMG_URL: `${process.env.REACT_APP_API_URL}/contestQuestionMedia`,
    CONTEST_IMG_URL: `${process.env.REACT_APP_API_URL}/contestImage`,
    PREDICTION_QUESTION_IMG_URL: `${process.env.REACT_APP_API_URL}/predictionQuestionMedia`,
    PREDICTION_IMG_URL: `${process.env.REACT_APP_API_URL}/predictionImage`,
    apiPrefix,
};


export default config;
