import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';

// helpers
import {
    reportedIssues as reportedIssuesApi,
} from '../../helpers';

// actions
import { reportedIssueApiResponseSuccess, reportedIssueApiResponseError } from './actions';

// constants
import { ReportedIssueActionTypes } from './constants';

interface ReportedIssueData {
    payload: {
        id: string,
        userId: string,
        issue: string,
        sortBy: string,
        page: number,
        limit: number,

    };
    type: string;
}

/**
 * ReportedIssue list
 */
function* reportedIssueList({ payload: {
    userId,
    issue,
    sortBy,
    limit,
    page
} }: ReportedIssueData): SagaIterator {
    try {

        const response = yield call(reportedIssuesApi, {
            userId,
            issue,
            sortBy,
            limit,
            page
        });
        yield put(reportedIssueApiResponseSuccess(
            ReportedIssueActionTypes.REPORTED_ISSUE_LIST,
            response.data.results,
            response.data.page,
            response.data.limit,
            response.data.totalPages,
            response.data.totalResults,
        ));
    } catch (error: any) {
        yield put(reportedIssueApiResponseError(ReportedIssueActionTypes.REPORTED_ISSUE_LIST, error));

    }
}




export function* watchReportedIssueList() {
    yield takeEvery(ReportedIssueActionTypes.REPORTED_ISSUE_LIST, reportedIssueList);
}






function* reportedIssueSaga() {
    yield all([fork(watchReportedIssueList)]);
}

export default reportedIssueSaga;
