import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';

// helpers
import {
    funFactQuestions as funFactQuestionsApi,
    addFunFactQuestion as addFunFactQuestionApi,
    updateFunFactQuestion as updateFunFactQuestionApi,
    deleteFunFactQuestion as deleteFunFactQuestionApi,
    importFunFactQuestions as importFunFactQuestionsApi,
} from '../../helpers';

// actions
import { funFactQuestionApiResponseSuccess, funFactQuestionApiResponseError } from './actions';

// constants
import { FunFactQuestionActionTypes } from './constants';

interface FunFactQuestionData {
    payload: {
        id: string;
        question: string;
        funFact: string;
        options: Array<{ option: string, selected: boolean }>;
        sortBy: string
        regions: any;
        limit: number
        page: number
        file: File
    };
    type: string;
}



/**
 * FunFactQuestion list
 */
function* funFactQuestionList({ payload: { question, sortBy, limit, page } }: FunFactQuestionData): SagaIterator {
    try {

        const response = yield call(funFactQuestionsApi, { question, sortBy, limit, page });

        yield put(funFactQuestionApiResponseSuccess(
            FunFactQuestionActionTypes.FUNFACT_QUESTION_LIST,
            response.data.results,
            response.data.page,
            response.data.limit,
            response.data.totalPages,
            response.data.totalResults
        ));
    } catch (error: any) {
        yield put(funFactQuestionApiResponseError(FunFactQuestionActionTypes.FUNFACT_QUESTION_LIST, error));

    }
}

/**
 * Add FunFactQuestion
 */
function* addFunFactQuestion({ payload: { question, funFact, options, regions } }: FunFactQuestionData): SagaIterator {
    try {

        const response = yield call(addFunFactQuestionApi, { question, funFact, options, regions });

        yield put(funFactQuestionApiResponseSuccess(
            FunFactQuestionActionTypes.ADD_FUNFACT_QUESTION,
            response.data.data
        ));
    } catch (error: any) {
        yield put(funFactQuestionApiResponseError(FunFactQuestionActionTypes.ADD_FUNFACT_QUESTION, error));

    }
}

/**
 * Update FunFactQuestion
 */
function* updateFunFactQuestion({ payload: { id, question, funFact, options, regions } }: FunFactQuestionData): SagaIterator {
    try {

        const response = yield call(updateFunFactQuestionApi, { question, funFact, options, regions }, id);

        yield put(funFactQuestionApiResponseSuccess(
            FunFactQuestionActionTypes.UPDATE_FUNFACT_QUESTION,
            response.data.data
        ));
    } catch (error: any) {
        yield put(funFactQuestionApiResponseError(FunFactQuestionActionTypes.UPDATE_FUNFACT_QUESTION, error));

    }
}
/**
 * Delete FunFactQuestion
 */
function* deleteFunFactQuestion({ payload: { id } }: FunFactQuestionData): SagaIterator {
    try {

        yield call(deleteFunFactQuestionApi, id);
        yield put(funFactQuestionApiResponseSuccess(
            FunFactQuestionActionTypes.DELETE_FUNFACT_QUESTION,
            id
        ));
    } catch (error: any) {
        yield put(funFactQuestionApiResponseError(FunFactQuestionActionTypes.DELETE_FUNFACT_QUESTION, error));

    }
}
/**
 * Import FunFact Questions
 */
function* importFunFactQuestions({ payload:
    {
        regions,
        file 
    } }: FunFactQuestionData): SagaIterator {
    try {

        const response = yield call(importFunFactQuestionsApi, {
            regions,
            file
        });
        yield put(funFactQuestionApiResponseSuccess(
            FunFactQuestionActionTypes.IMPORT_FUNFACT_QUESTION,
            response.data.data
        ));
    } catch (error: any) {
        console.log(error);

        // yield put(funFactQuestionApiResponseError(FunFactQuestionActionTypes.IMPORT_FUNFACT_QUESTION, error));

    }
}




export function* watchFunFactQuestionList() {
    yield takeEvery(FunFactQuestionActionTypes.FUNFACT_QUESTION_LIST, funFactQuestionList);
}

export function* watchAddFunFactQuestion() {
    yield takeEvery(FunFactQuestionActionTypes.ADD_FUNFACT_QUESTION, addFunFactQuestion);
}

export function* watchUpdateFunFactQuestion() {
    yield takeEvery(FunFactQuestionActionTypes.UPDATE_FUNFACT_QUESTION, updateFunFactQuestion);
}

export function* watchDeleteFunFactQuestion() {
    yield takeEvery(FunFactQuestionActionTypes.DELETE_FUNFACT_QUESTION, deleteFunFactQuestion);
}

export function* watchImportFunFactQuestions() {
    yield takeEvery(FunFactQuestionActionTypes.IMPORT_FUNFACT_QUESTION, importFunFactQuestions);
}




function* funFactQuestionSaga() {
    yield all([fork(watchFunFactQuestionList), fork(watchAddFunFactQuestion), fork(watchUpdateFunFactQuestion), fork(watchDeleteFunFactQuestion), fork(watchImportFunFactQuestions)]);
}

export default funFactQuestionSaga;
