// constants
import { CategoryActionTypes } from './constants';

export interface CategoryActionType {
    type:
    | CategoryActionTypes.API_RESPONSE_SUCCESS
    | CategoryActionTypes.API_RESPONSE_ERROR
    | CategoryActionTypes.CATEGORY_LIST
    | CategoryActionTypes.ADD_CATEGORY
    | CategoryActionTypes.UPDATE_CATEGORY
    | CategoryActionTypes.SHOW_CATEGORY
    | CategoryActionTypes.DELETE_CATEGORY
    payload: {} | string;
}



// common success
export const categoryApiResponseSuccess = (actionType: string, data: any, page: number = 0, limit: number = 0, totalPages: number = 0, totalResults: number = 0): CategoryActionType => ({
    type: CategoryActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data, page, limit, totalPages, totalResults },
});
// common error
export const categoryApiResponseError = (actionType: string, error: string): CategoryActionType => ({
    type: CategoryActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const addCategory = (name: string, description: string, image: string): CategoryActionType => ({
    type: CategoryActionTypes.ADD_CATEGORY,
    payload: { name, description, image },
});
interface FiltersData {
    name: string,
    status: string,
    sortBy: string,
    limit: number,
    page: number,
};


export const getCategory = ({
    name,
    status,
    sortBy,
    limit,
    page,
}: FiltersData): CategoryActionType => ({
    type: CategoryActionTypes.CATEGORY_LIST,
    payload: {
        name,
        status,
        sortBy,
        limit,
        page,
    },
});

export const showCategory = (id: string): CategoryActionType => ({
    type: CategoryActionTypes.SHOW_CATEGORY,
    payload: { id },
});

export const deleteCategory = (id: string): CategoryActionType => ({
    type: CategoryActionTypes.DELETE_CATEGORY,
    payload: { id },
});

export const updateCategory = (id: string, name: string, description: string, status: string, image: string, updateImage: boolean): CategoryActionType => ({
    type: CategoryActionTypes.UPDATE_CATEGORY,
    payload: { id, name, description, status, image, updateImage },
});



