import { APICore } from './apiCore';
const api = new APICore();

// account
function login(params: { email: string; password: string }) {
    const baseUrl = `/auth/login`;
    return api.create(`${baseUrl}`, params);

}

function logout() {
    const { refreshToken } = api.getLoggedInUser();
    const baseUrl = '/auth/logout/';
    return api.create(`${baseUrl}`, {refreshToken});
}

function signup(params: { fullname: string; email: string; password: string }) {
    const baseUrl = '/register/';
    return api.create(`${baseUrl}`, params);
}

function forgotPassword(params: { email: string }) {
    const baseUrl = '/forget-password/';
    return api.create(`${baseUrl}`, params);
}

export { login, logout, signup, forgotPassword };
