// constants
import { UserActionTypes } from './constants';

const INIT_STATE = {
    users: [],
    user: {},
    page: 1,
    limit: 50,
    totalPages: 0,
    totalResults: 0,
    loading: false,
    hasErrors: false,
    submitted: false,
};

interface UserActionType {
    type:
    | UserActionTypes.API_RESPONSE_SUCCESS
    | UserActionTypes.API_RESPONSE_ERROR
    | UserActionTypes.USER_LIST
    | UserActionTypes.ADD_USER
    | UserActionTypes.SHOW_USER
    | UserActionTypes.UPDATE_USER

    payload: {
        actionType?: string;
        data?: any;
        page?: number;
        limit?: number;
        totalPages?: number;
        totalResults?: number;
        error?: any;
        validationError?: { error: string; key: string; }[];
        hasErrors: boolean,
        submitted: boolean,
    };
}

interface State {
    users: any;
    user: any;
    page: number;
    limit: number;
    totalPages: number;
    totalResults: number;
}

const User = (state: State = INIT_STATE, action: UserActionType): any => {
    switch (action.type) {
        case UserActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case UserActionTypes.USER_LIST: {
                    return {
                        ...state,
                        users: action.payload.data,
                        page: action.payload.page,
                        limit: action.payload.limit,
                        totalPages: action.payload.totalPages,
                        totalResults: action.payload.totalResults,
                        loading: true,
                        submitted: false,
                    };
                }
                case UserActionTypes.UPDATE_USER: {
                    const updatedUser = action.payload.data;
                    const allUsers = state.users;
                    const updatedUsers = allUsers.map((qq: any) => qq.id === updatedUser.id ? updatedUser : qq);
                    return {
                        ...state,
                        users: updatedUsers,
                        hasErrors: false,
                        loading: true,
                        submitted: true,
                    };
                }
                case UserActionTypes.SHOW_USER: {
                    const updatedUser = action.payload.data;
                    return {
                        ...state,
                        user: updatedUser,
                        hasErrors: false,
                        loading: true,
                        submitted: false,
                    };
                }
                case UserActionTypes.DELETE_USER: {
                    const deletedId = action.payload.data;
                    const allUsers = state.users;
                    const updatedUsers = allUsers.filter((cat: any) => cat.id !== deletedId);
                    return {
                        ...state,
                        users: updatedUsers,
                        hasErrors: false,
                        loading: true,
                        submitted: true,
                    };
                }
                default:
                    return { ...state };
            }

        case UserActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case UserActionTypes.USER_LIST: {
                    return {
                        ...state,
                        error: action.payload.error,
                        hasErrors: true,
                        loading: true,
                        submitted: false,
                    };
                }
                case UserActionTypes.UPDATE_USER: {
                    return {
                        ...state,
                        error: action.payload.error,
                        validationError: action.payload.error,
                        hasErrors: true,
                        loading: true,
                        submitted: true,
                    };
                }
                case UserActionTypes.SHOW_USER: {
                    return {
                        ...state,
                        error: action.payload.error,
                        validationError: action.payload.error,
                        hasErrors: true,
                        loading: true,
                        submitted: false,
                    };
                }
                default:
                    return { ...state };
            }
        default:
            return { ...state };
    }
};

export default User;
