// constants
import { WinnerCoinRankActionTypes } from './constants';

export interface WinnerCoinRankActionType {
    type:
    | WinnerCoinRankActionTypes.API_RESPONSE_SUCCESS
    | WinnerCoinRankActionTypes.API_RESPONSE_ERROR
    | WinnerCoinRankActionTypes.WINNER_COIN_RANK_LIST
    | WinnerCoinRankActionTypes.ADD_WINNER_COIN_RANK
    | WinnerCoinRankActionTypes.UPDATE_WINNER_COIN_RANK
    | WinnerCoinRankActionTypes.SHOW_WINNER_COIN_RANK
    | WinnerCoinRankActionTypes.DELETE_WINNER_COIN_RANK
    payload: {} | string;
}


// common success
export const winnerCoinRankApiResponseSuccess = (actionType: string, data: any, page: number = 0, limit: number = 0, totalPages: number = 0, totalResults: number = 0): WinnerCoinRankActionType => ({
    type: WinnerCoinRankActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data, page, limit, totalPages, totalResults },
});
// common error
export const winnerCoinRankApiResponseError = (actionType: string, error: string): WinnerCoinRankActionType => ({
    type: WinnerCoinRankActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

interface WinnerCoinRankData {
    id: string,
    name: string,
    status: string,
    rankWiseCoins: Array<number>,
}

export const addWinnerCoinRank = ({
    name,
    rankWiseCoins,
}: WinnerCoinRankData
): WinnerCoinRankActionType => ({
    type: WinnerCoinRankActionTypes.ADD_WINNER_COIN_RANK,
    payload: {
        name,
        rankWiseCoins
    },
});

interface FiltersData {
    name: string,
    status: string,
    sortBy: string,
    limit: number,
    page: number,
};

export const getWinnerCoinRank = ({
    name,
    status,
    sortBy,
    limit,
    page,
}: FiltersData): WinnerCoinRankActionType => ({
    type: WinnerCoinRankActionTypes.WINNER_COIN_RANK_LIST,
    payload: {
        name,
        status,
        sortBy,
        limit,
        page,
    },
});

export const showWinnerCoinRank = (id: string): WinnerCoinRankActionType => ({
    type: WinnerCoinRankActionTypes.SHOW_WINNER_COIN_RANK,
    payload: { id },
});

export const deleteWinnerCoinRank = (id: string): WinnerCoinRankActionType => ({
    type: WinnerCoinRankActionTypes.DELETE_WINNER_COIN_RANK,
    payload: { id },
});

export const updateWinnerCoinRank = ({
    id,
    name,
    status,
    rankWiseCoins,
}: WinnerCoinRankData
): WinnerCoinRankActionType => ({
    type: WinnerCoinRankActionTypes.UPDATE_WINNER_COIN_RANK,
    payload: {
        id,
        name,
        status,
        rankWiseCoins,
    },
});





