import { APICore } from './apiCore';
const api = new APICore();

// contest
// eslint-disable-next-line no-empty-pattern


function contests(params: {
    name: string,
    status: string,
    region: string,
    visibility_status: string,
    category: string,
    sortBy: string,
    limit: number,
    page: number,
}) {
    const baseUrl = `/contest`;
    return api.get(`${baseUrl}`, params);

}
function addContest(params: {
    name: string,
    region: string,
    contestImage: string,
    category: string,
    joining_fee: number,
    duration: number,
    marking: number,
    negative_marking: number,
    pool_size: number,
    contest_question_count: number,
    questions: Array<string>,
    schedules: Array<any>,
    winnerAmount: Array<number>,
    lifeLineCharge: number,
    startDate: Date,
    endDate: Date,
    startTime: Date,
    endTime: Date,
    // resultDate: Date,
    winCoins: Number,
}) {
    const baseUrl = `/contest`;
    return api.create(`${baseUrl}`, params);

}
function updateContestStatus(params: {
    status: string,
}, id: string) {
    const baseUrl = `/contest/${id}/updateStatus`;
    return api.updatePatch(`${baseUrl}`, params);

}
function updateContest(params: {
    name: string,
    contestImage: string,
    joining_fee: number,
    duration: number,
    schedules: Array<any>,
    lifeLineCharge: number,
    startDate: Date,
    updateImage: boolean,
    endDate: Date,
    resultDate: Date,
    winCoins: Number,
}, id: string) {
    const baseUrl = `/contest/${id}`;
    return api.updatePatch(`${baseUrl}`, params);

}

function deleteContest(id: string) {
    const baseUrl = `/contest/${id}`;
    return api.delete(`${baseUrl}`);

}

function showContest(id: string) {
    const baseUrl = `/contest/${id}`;
    return api.getById(`${baseUrl}`);

}

function uploadContestFile(params: { file: File[] }) {
    const baseUrl = `/contest/uploadContestImage`;
    return api.uploadFile(`${baseUrl}`, params);
}



export { contests, addContest,showContest, updateContest, deleteContest, uploadContestFile ,updateContestStatus };
