// constants
import { ReportedIssueActionTypes } from './constants';

const INIT_STATE = {
    reportedIssues: [],
    page: 1,
    limit: 50,
    totalPages: 0,
    totalResults: 0,
    loading: false,
    hasErrors: false,
    submitted: false,
};

interface ReportedIssueActionType {
    type:
    | ReportedIssueActionTypes.API_RESPONSE_SUCCESS
    | ReportedIssueActionTypes.API_RESPONSE_ERROR
    | ReportedIssueActionTypes.REPORTED_ISSUE_LIST
    payload: {
        actionType?: string;
        data?: any;
        page?: number;
        limit?: number;
        totalPages?: number;
        totalResults?: number;
        error?: any;
        validationError?: { error: string; key: string; }[];
        hasErrors: boolean,
        submitted: boolean,
    };
}

interface State {
    reportedIssues: any;
    page: number;
    limit: number;
    totalPages: number;
    totalResults: number;
}

const ReportedIssue = (state: State = INIT_STATE, action: ReportedIssueActionType): any => {
    switch (action.type) {
        case ReportedIssueActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case ReportedIssueActionTypes.REPORTED_ISSUE_LIST: {
                    return {
                        ...state,
                        reportedIssues: action.payload.data,
                        page: action.payload.page,
                        limit: action.payload.limit,
                        totalPages: action.payload.totalPages,
                        totalResults: action.payload.totalResults,
                        loading: true,
                        submitted: false,
                    };
                }
                default:
                    return { ...state };
            }

        case ReportedIssueActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case ReportedIssueActionTypes.REPORTED_ISSUE_LIST: {
                    return {
                        ...state,
                        error: action.payload.error,
                        hasErrors: true,
                        loading: true,
                        submitted: false,
                    };
                }
                default:
                    return { ...state };
            }
        default:
            return { ...state };
    }
};

export default ReportedIssue;
