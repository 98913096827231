import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';

// helpers
import {
    reportedQuestions as reportedQuestionsApi,
} from '../../helpers';

// actions
import { reportedQuestionApiResponseSuccess, reportedQuestionApiResponseError } from './actions';

// constants
import { ReportedQuestionActionTypes } from './constants';

interface ReportedQuestionData {
    payload: {
        id: string,
        userId: string,
        questionId: string,
        questionType: string,
        sortBy: string,
        page: number,
        limit: number,

    };
    type: string;
}

/**
 * ReportedQuestion list
 */
function* reportedQuestionList({ payload: {
    userId,
    questionId,
    questionType,
    sortBy,
    limit,
    page
} }: ReportedQuestionData): SagaIterator {
    try {

        const response = yield call(reportedQuestionsApi, {
            userId,
            questionId,
            questionType,
            sortBy,
            limit,
            page
        });
        yield put(reportedQuestionApiResponseSuccess(
            ReportedQuestionActionTypes.REPORTED_QUESTION_LIST,
            response.data.results,
            response.data.page,
            response.data.limit,
            response.data.totalPages,
            response.data.totalResults,
        ));
    } catch (error: any) {
        yield put(reportedQuestionApiResponseError(ReportedQuestionActionTypes.REPORTED_QUESTION_LIST, error));

    }
}




export function* watchReportedQuestionList() {
    yield takeEvery(ReportedQuestionActionTypes.REPORTED_QUESTION_LIST, reportedQuestionList);
}






function* reportedQuestionSaga() {
    yield all([fork(watchReportedQuestionList)]);
}

export default reportedQuestionSaga;
