import { all } from 'redux-saga/effects';

import authSaga from './auth/saga';
import layoutSaga from './layout/saga';
import categorySaga from './category/saga';
import quizQuestionSaga from './quizQuestion/saga';
import quizSaga from './quiz/saga';
import contestQuestionSaga from './contestQuestion/saga';
import contestSaga from './contest/saga';
import PredictionQuestionSaga from './predictionQuestion/saga';
import PredictionSaga from './prediction/saga';
import FunFactQuestionSaga from './funFactQuestion/saga';
import UserSaga from './user/saga';
import ReportedIssueSaga from './reportedIssue/saga';
import ReportedQuestionSaga from './reportedQuestion/saga';
import DashboardSaga from './dashboard/saga';
import WinnerCoinRankSaga from './winnerCoinRank/saga';

export default function* rootSaga() {
    yield all([
        authSaga(),
        layoutSaga(),
        categorySaga(),
        quizQuestionSaga(),
        quizSaga(),
        contestQuestionSaga(),
        contestSaga(),
        PredictionQuestionSaga(),
        PredictionSaga(),
        FunFactQuestionSaga(),
        UserSaga(),
        ReportedIssueSaga(),
        ReportedQuestionSaga(),
        DashboardSaga(),
        WinnerCoinRankSaga(),
    ]);
}
