// constants
import { WinnerCoinRankActionTypes } from './constants';

const INIT_STATE = {
    winnerCoinRanks: [],
    page: 1,
    limit: 10,
    totalPages: 0,
    totalResults: 0,
    categories:[],
    loading: false,
    hasErrors: false,
    submitted: false,
};

interface WinnerCoinRankActionType {
    type:
    | WinnerCoinRankActionTypes.API_RESPONSE_SUCCESS
    | WinnerCoinRankActionTypes.API_RESPONSE_ERROR
    | WinnerCoinRankActionTypes.WINNER_COIN_RANK_LIST
    | WinnerCoinRankActionTypes.ADD_WINNER_COIN_RANK
    | WinnerCoinRankActionTypes.SHOW_WINNER_COIN_RANK
    | WinnerCoinRankActionTypes.UPDATE_WINNER_COIN_RANK

    payload: {
        actionType?: string;
        data?: any;
        page?: number;
        limit?: number;
        totalPages?: number;
        totalResults?: number;
        categories?: any;
        error?: any;
        validationError?: { error: string; key: string; }[];
        hasErrors: boolean,
        submitted: boolean,
    };
}

interface State {
    winnerCoinRanks: any;
    page: number;
    limit: number;
    totalPages: number;
    totalResults: number;
}

const WinnerCoinRank = (state: State = INIT_STATE, action: WinnerCoinRankActionType): any => {
    switch (action.type) {
        case WinnerCoinRankActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case WinnerCoinRankActionTypes.WINNER_COIN_RANK_LIST: {
                    return {
                        ...state,
                        winnerCoinRanks: action.payload.data,
                        page: action.payload.page,
                        limit: action.payload.limit,
                        totalPages: action.payload.totalPages,
                        totalResults: action.payload.totalResults,
                        loading: true,
                        submitted: false,
                    };
                }
                case WinnerCoinRankActionTypes.ADD_WINNER_COIN_RANK: {
                    return {
                        ...state,
                        winnerCoinRanks: [...state.winnerCoinRanks, action.payload.data],
                        totalResults: (action.payload.totalResults ? (action.payload.totalResults + 1) : 0),
                        hasErrors: false,
                        loading: true,
                        submitted: true,
                    };
                }
                case WinnerCoinRankActionTypes.UPDATE_WINNER_COIN_RANK: {
                    const updatedWinnerCoinRank = action.payload.data;
                    const allWinnerCoinRanks = state.winnerCoinRanks;
                    const updatedWinnerCoinRanks = allWinnerCoinRanks.map((qq: any) => qq.id === updatedWinnerCoinRank.id ? updatedWinnerCoinRank : qq);
                    return {
                        ...state,
                        winnerCoinRanks: updatedWinnerCoinRanks,
                        hasErrors: false,
                        loading: true,
                        submitted: true,
                    };
                }
                case WinnerCoinRankActionTypes.DELETE_WINNER_COIN_RANK: {
                    const deletedId = action.payload.data;
                    const allWinnerCoinRanks = state.winnerCoinRanks;
                    const updatedWinnerCoinRanks = allWinnerCoinRanks.filter((cat: any) => cat.id !== deletedId);
                    return {
                        ...state,
                        winnerCoinRanks: updatedWinnerCoinRanks,
                        hasErrors: false,
                        loading: true,
                        submitted: true,
                    };
                }
                default:
                    return { ...state };
            }

        case WinnerCoinRankActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case WinnerCoinRankActionTypes.WINNER_COIN_RANK_LIST: {
                    return {
                        ...state,
                        error: action.payload.error,
                        hasErrors: true,
                        loading: true,
                        submitted: false,
                    };
                }
                case WinnerCoinRankActionTypes.ADD_WINNER_COIN_RANK: {
                    return {
                        ...state,
                        error: action.payload.error,
                        validationError: action.payload.error,
                        hasErrors: true,
                        loading: true,
                        submitted: true,
                    };
                }
                case WinnerCoinRankActionTypes.UPDATE_WINNER_COIN_RANK: {
                    return {
                        ...state,
                        error: action.payload.error,
                        validationError: action.payload.error,
                        hasErrors: true,
                        loading: true,
                        submitted: true,
                    };
                }
                default:
                    return { ...state };
            }
        default:
            return { ...state };
    }
};

export default WinnerCoinRank;
