import { APICore } from './apiCore';
const api = new APICore();

// predictionQuestion
// eslint-disable-next-line no-empty-pattern
function predictionQuestions(params: {
    question: string,
    regions: string,
    category: string,
    type: string,
    correctOption: any,
    sortBy: string,
    limit: number,
    page: number;
}) {
    const baseUrl = `/predictionQuestion`;
    return api.get(`${baseUrl}`, params);

}
function addPredictionQuestion(params: { question: string, type: string, media: string, category: string, options: Array<{ option: string, selected: boolean }>, level: string, regions: Array<any> }) {
    const baseUrl = `/predictionQuestion/`;
    return api.create(`${baseUrl}`, params);

}
function updatePredictionQuestion(params: { question: string, type: string, media: string, category: string, options: Array<{ option: string, selected: boolean }>, level: string, regions: Array<any>, updateMedia: boolean }, id: string) {
    const baseUrl = `/predictionQuestion/${id}`;
    return api.updatePatch(`${baseUrl}`, params);

}

function updatePredictionQuestionAnswer(params: { correctOption: string }, id: string) {
    const baseUrl = `/predictionQuestion/${id}`;
    return api.update(`${baseUrl}`, params);

}

function deletePredictionQuestion(id: string) {
    const baseUrl = `/predictionQuestion/${id}`;
    return api.delete(`${baseUrl}`);

}

function uploadPredictionQuestionFile(params: { file: File[] }) {
    const baseUrl = `/predictionQuestion/uploadQuestionMedia`;
    return api.uploadFile(`${baseUrl}`, params);
}
function importPredictionQuestions(params: {
    category: string,
    regions: string,
    file: File
}) {
    const baseUrl = `/predictionQuestion/importQuestions`;
    return api.createWithFile(`${baseUrl}`, params);
}



export { predictionQuestions, addPredictionQuestion, updatePredictionQuestion, deletePredictionQuestion, uploadPredictionQuestionFile, importPredictionQuestions, updatePredictionQuestionAnswer };
