import { APICore } from './apiCore';
const api = new APICore();

// prediction
// eslint-disable-next-line no-empty-pattern


function predictions(params: {
    name: string,
    status: string,
    region: string,
    category: string,
    sortBy: string,
    limit: number,
    page: number,
}) {
    const baseUrl = `/prediction`;
    return api.get(`${baseUrl}`, params);

}

function addPrediction(params: {
    name: string,
    region: string,
    category: string,
    joining_fee: number,
    marking: number,
    negative_marking: number,
    prediction_question_count: number,
    questions: Array<string>,
    schedules: Array<any>,
    startDate: Date,
    endDate: Date,
    resultDate: Date,
    firstContenderTitle: string,
    secondContenderTitle: string,
    firstContenderImage: string,
    secondContenderImage: string,
    winnerAmount: number,
}) {
    const baseUrl = `/prediction`;
    return api.create(`${baseUrl}`, params);

}

function updatePredictionStatus(params: {
    status: string,
}, id: string) {
    const baseUrl = `/prediction/${id}/updateStatus`;
    return api.updatePatch(`${baseUrl}`, params);

}
function updatePrediction(params: {
    name: string,
    joining_fee: number,
    schedules: Array<any>,
    startDate: Date,
    endDate: Date,
    resultDate: Date,
    firstContenderTitle: string,
    secondContenderTitle: string,
    firstContenderImage: string,
    secondContenderImage: string,
    winnerAmount: number,
    updateFirstContenderImage:boolean,
    updateSecondContenderImage:boolean,
}, id: string) {
    const baseUrl = `/prediction/${id}`;
    return api.updatePatch(`${baseUrl}`, params);

}
function showPrediction(id: string) {
    const baseUrl = `/prediction/${id}`;
    return api.getById(`${baseUrl}`);

}

function deletePrediction(id: string) {
    const baseUrl = `/prediction/${id}`;
    return api.delete(`${baseUrl}`);

}

function uploadPredictionFile(params: { file: File[] }) {
    const baseUrl = `/prediction/uploadPredictionImage`;
    return api.uploadFile(`${baseUrl}`, params);
}



export { predictions, addPrediction, updatePrediction, deletePrediction,updatePredictionStatus, uploadPredictionFile, showPrediction };
