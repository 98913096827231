// constants
import { CategoryActionTypes } from './constants';

const INIT_STATE = {
    categories: [],
    page: 1,
    limit: 50,
    totalPages: 0,
    totalResults: 0,
    loading: false,
    hasErrors: false,
    submitted: false,
 
};

interface CategoryActionType {
    type:
    | CategoryActionTypes.API_RESPONSE_SUCCESS
    | CategoryActionTypes.API_RESPONSE_ERROR
    | CategoryActionTypes.CATEGORY_LIST
    | CategoryActionTypes.ADD_CATEGORY
    | CategoryActionTypes.SHOW_CATEGORY
    | CategoryActionTypes.UPDATE_CATEGORY

    payload: {
        actionType?: string;
        data?: any;
        page?: number;
        limit?: number;
        totalPages?: number;
        totalResults?: number;
        error?: any;
        validationError?: { error: string; key: string; }[];
        hasErrors: boolean,
        submitted: boolean,
    };
}

interface State {
    categories: any;
    page: number;
    limit: number;
    totalPages: number;
    totalResults: number;
}

const Category = (state: State = INIT_STATE, action: CategoryActionType): any => {
    switch (action.type) {
        case CategoryActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case CategoryActionTypes.CATEGORY_LIST: {
                    return {
                        ...state,
                        categories: action.payload.data,
                        page: action.payload.page,
                        limit: action.payload.limit,
                        totalPages: action.payload.totalPages,
                        totalResults: action.payload.totalResults,
                        loading: true,
                        submitted: false,
                    };
                }
                case CategoryActionTypes.ADD_CATEGORY: {
                    return {
                        ...state,
                        categories: [...state.categories, action.payload.data],
                        totalResults: (action.payload.totalResults ? (action.payload.totalResults + 1) : 0),
                        hasErrors: false,
                        loading: true,
                        submitted: true,
                    };
                }
                case CategoryActionTypes.UPDATE_CATEGORY: {
                    const updatedCategory = action.payload.data;
                    const allCategories = state.categories;
                    const updatedCategories = allCategories.map((cat: any) => cat.id === updatedCategory.id ? updatedCategory : cat);
                    return {
                        ...state,
                        categories: updatedCategories,
                        hasErrors: false,
                        loading: true,
                        submitted: true,
                    };
                }
                case CategoryActionTypes.DELETE_CATEGORY: {
                    const deletedId = action.payload.data;
                    const allCategories = state.categories;
                    const updatedCategories = allCategories.filter((cat: any) => cat.id !== deletedId);
                    return {
                        ...state,
                        categories: updatedCategories,
                        loading: true,
                        hasErrors: false,
                        submitted: true,
                    };
                }
                default:
                    return { ...state };
            }

        case CategoryActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case CategoryActionTypes.CATEGORY_LIST: {
                    return {
                        ...state,
                        error: action.payload.error,
                        hasErrors: true,
                        loading: true,
                        submitted: false,
                    };
                }
                case CategoryActionTypes.ADD_CATEGORY: {
                    return {
                        ...state,
                        error: action.payload.error,
                        validationError: action.payload.error,
                        hasErrors: true,
                        loading: true,
                        submitted: true,
                    };
                }
                case CategoryActionTypes.UPDATE_CATEGORY: {
                    return {
                        ...state,
                        error: action.payload.error,
                        validationError: action.payload.error,
                        hasErrors: true,
                        loading: true,
                        submitted: true,
                    };
                }
                default:
                    return { ...state };
            }
        default:
            return { ...state };
    }
};

export default Category;
