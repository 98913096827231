// constants
import config from '../../config';
import { ContestQuestionActionTypes } from './constants';

const INIT_STATE = {
    contestQuestions: [],
    page: 1,
    limit: 50,
    totalPages: 0,
    totalResults: 0,
    contestQuestionFile: '',
    loading: false,
    hasErrors: false,
    submitted: false,
    uploadedQuestions: false,
};

interface ContestQuestionActionType {
    type:
    | ContestQuestionActionTypes.API_RESPONSE_SUCCESS
    | ContestQuestionActionTypes.API_RESPONSE_ERROR
    | ContestQuestionActionTypes.CONTEST_QUESTION_LIST
    | ContestQuestionActionTypes.ADD_CONTEST_QUESTION
    | ContestQuestionActionTypes.SHOW_CONTEST_QUESTION
    | ContestQuestionActionTypes.UPDATE_CONTEST_QUESTION

    payload: {
        actionType?: string;
        data?: any;
        page?: number;
        limit?: number;
        totalPages?: number;
        totalResults?: number;
        categories?: any;
        winnerCoinRanks?: any;
        contestQuestionFile?: string;
        error?: any;
        validationError?: { error: string; key: string; }[];
        hasErrors: boolean,
        submitted: boolean,
    };
}

interface State {
    contestQuestions: any;
    page: number;
    limit: number;
    totalPages: number;
    totalResults: number;
    contestQuestionFile: string;
    uploadedQuestions: boolean,
}

const ContestQuestion = (state: State = INIT_STATE, action: ContestQuestionActionType): any => {
    switch (action.type) {
        case ContestQuestionActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case ContestQuestionActionTypes.CONTEST_QUESTION_LIST: {
                    return {
                        ...state,
                        contestQuestions: action.payload.data,
                        page: action.payload.page,
                        limit: action.payload.limit,
                        totalPages: action.payload.totalPages,
                        totalResults: action.payload.totalResults,
                        categories: action.payload.categories,
                        winnerCoinRanks: action.payload.winnerCoinRanks,
                        loading: true,
                        submitted: false,
                        uploadedQuestions: false,
                    };
                }
                case ContestQuestionActionTypes.ADD_CONTEST_QUESTION: {
                    return {
                        ...state,
                        contestQuestions: [...state.contestQuestions, action.payload.data],
                        totalResults: (action.payload.totalResults ? (action.payload.totalResults + 1) : 0),
                        hasErrors: false,
                        loading: true,
                        submitted: true,
                        uploadedQuestions: false,
                    };
                }
                case ContestQuestionActionTypes.UPDATE_CONTEST_QUESTION: {
                    const updatedContestQuestion = action.payload.data;
                    const allContestQuestions = state.contestQuestions;
                    const updatedContestQuestions = allContestQuestions.map((qq: any) => qq.id === updatedContestQuestion.id ? updatedContestQuestion : qq);
                    return {
                        ...state,
                        contestQuestions: updatedContestQuestions,
                        hasErrors: false,
                        loading: true,
                        submitted: true,
                        uploadedQuestions: false,
                    };
                }
                case ContestQuestionActionTypes.DELETE_CONTEST_QUESTION: {
                    const deletedId = action.payload.data;
                    const allContestQuestions = state.contestQuestions;
                    const updatedContestQuestions = allContestQuestions.filter((cat: any) => cat.id !== deletedId);
                    return {
                        ...state,
                        contestQuestions: updatedContestQuestions,
                        hasErrors: false,
                        loading: true,
                        submitted: true,
                        uploadedQuestions: false,
                    };
                }
                case ContestQuestionActionTypes.IMPORT_CONTEST_QUESTION: {
                    const fileData = action.payload.data;
                    var FileSaver = require('file-saver');
                    const file = `${config.EXCEL_URL}/${fileData}`
                    FileSaver.saveAs(file, "contest-question-progress.xlsx", { autoBom: true });
                    
                    return {
                        ...state,
                        hasErrors: false,
                        loading: true,
                        submitted: true,
                        uploadedQuestions: true,
                    };
                }
                default:
                    return { ...state };
            }

        case ContestQuestionActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case ContestQuestionActionTypes.CONTEST_QUESTION_LIST: {
                    return {
                        ...state,
                        error: action.payload.error,
                        hasErrors: true,
                        loading: true,
                        submitted: false,
                    };
                }
                case ContestQuestionActionTypes.ADD_CONTEST_QUESTION: {
                    return {
                        ...state,
                        error: action.payload.error,
                        validationError: action.payload.error,
                        hasErrors: true,
                        loading: true,
                        submitted: true,
                    };
                }
                case ContestQuestionActionTypes.UPDATE_CONTEST_QUESTION: {
                    return {
                        ...state,
                        error: action.payload.error,
                        validationError: action.payload.error,
                        hasErrors: true,
                        loading: true,
                        submitted: true,
                    };
                }
                case ContestQuestionActionTypes.IMPORT_CONTEST_QUESTION: {
                    return {
                        ...state,
                        error: action.payload.error,
                        validationError: action.payload.error,
                        hasErrors: true,
                        loading: true,
                        submitted: true,
                        uploadedQuestions: true,
                    };
                }
                default:
                    return { ...state };
            }
        default:
            return { ...state };
    }
};

export default ContestQuestion;
