// constants
import config from '../../config';
import { FunFactQuestionActionTypes } from './constants';

const INIT_STATE = {
    funFactQuestions: [],
    page: 1,
    limit: 50,
    totalPages: 0,
    totalResults: 0,
    loading: false,
    hasErrors: false,
    submitted: false,
    uploadedQuestions: false,
};

interface FunFactQuestionActionType {
    type:
    | FunFactQuestionActionTypes.API_RESPONSE_SUCCESS
    | FunFactQuestionActionTypes.API_RESPONSE_ERROR
    | FunFactQuestionActionTypes.FUNFACT_QUESTION_LIST
    | FunFactQuestionActionTypes.ADD_FUNFACT_QUESTION
    | FunFactQuestionActionTypes.SHOW_FUNFACT_QUESTION
    | FunFactQuestionActionTypes.UPDATE_FUNFACT_QUESTION

    payload: {
        actionType?: string;
        data?: any;
        page?: number;
        limit?: number;
        totalPages?: number;
        totalResults?: number;
        funFactQuestionFile?: string;
        error?: any;
        validationError?: { error: string; key: string; }[];
        hasErrors: boolean,
        submitted: boolean,
    };
}

interface State {
    funFactQuestions: any;
    page: number;
    limit: number;
    totalPages: number;
    totalResults: number;
    uploadedQuestions: boolean,
}

const FunFactQuestion = (state: State = INIT_STATE, action: FunFactQuestionActionType): any => {
    switch (action.type) {
        case FunFactQuestionActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case FunFactQuestionActionTypes.FUNFACT_QUESTION_LIST: {
                    return {
                        ...state,
                        funFactQuestions: action.payload.data,
                        page: action.payload.page,
                        limit: action.payload.limit,
                        totalPages: action.payload.totalPages,
                        totalResults: action.payload.totalResults,
                        loading: true,
                        submitted: false,
                        uploadedQuestions: false,
                    };
                }
                case FunFactQuestionActionTypes.ADD_FUNFACT_QUESTION: {
                    return {
                        ...state,
                        funFactQuestions: [...state.funFactQuestions, action.payload.data],
                        totalResults: (action.payload.totalResults ? (action.payload.totalResults + 1) : 0),
                        hasErrors: false,
                        loading: true,
                        submitted: true,
                        uploadedQuestions: false,
                    };
                }
                case FunFactQuestionActionTypes.UPDATE_FUNFACT_QUESTION: {
                    const updatedFunFactQuestion = action.payload.data;
                    const allFunFactQuestions = state.funFactQuestions;
                    const updatedFunFactQuestions = allFunFactQuestions.map((qq: any) => qq.id === updatedFunFactQuestion.id ? updatedFunFactQuestion : qq);
                    return {
                        ...state,
                        funFactQuestions: updatedFunFactQuestions,
                        hasErrors: false,
                        loading: true,
                        submitted: true,
                        uploadedQuestions: false,
                    };
                }
                case FunFactQuestionActionTypes.DELETE_FUNFACT_QUESTION: {
                    const deletedId = action.payload.data;
                    const allFunFactQuestions = state.funFactQuestions;
                    const updatedFunFactQuestions = allFunFactQuestions.filter((cat: any) => cat.id !== deletedId);
                    return {
                        ...state,
                        funFactQuestions: updatedFunFactQuestions,
                        hasErrors: false,
                        loading: true,
                        submitted: false,
                        uploadedQuestions: false,
                    };
                }
                case FunFactQuestionActionTypes.IMPORT_FUNFACT_QUESTION: {
                    const fileData = action.payload.data;
                    var FileSaver = require('file-saver');
                    const file = `${config.EXCEL_URL}/${fileData}`
                    FileSaver.saveAs(file, "funFact-question-progress.xlsx", { autoBom: true });
                    
                    return {
                        ...state,
                        hasErrors: false,
                        loading: true,
                        submitted: true,
                        uploadedQuestions: true,
                    };
                }
                default:
                    return { ...state };
            }

        case FunFactQuestionActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case FunFactQuestionActionTypes.FUNFACT_QUESTION_LIST: {
                    return {
                        ...state,
                        error: action.payload.error,
                        hasErrors: true,
                        loading: true,
                        submitted: false,
                    };
                }
                case FunFactQuestionActionTypes.ADD_FUNFACT_QUESTION: {
                    return {
                        ...state,
                        error: action.payload.error,
                        validationError: action.payload.error,
                        hasErrors: true,
                        loading: true,
                        submitted: true,
                    };
                }
                case FunFactQuestionActionTypes.UPDATE_FUNFACT_QUESTION: {
                    return {
                        ...state,
                        error: action.payload.error,
                        validationError: action.payload.error,
                        hasErrors: true,
                        loading: true,
                        submitted: true,
                    };
                }
                case FunFactQuestionActionTypes.DELETE_FUNFACT_QUESTION: {
                    return {
                        ...state,
                        error: action.payload.error,
                        validationError: action.payload.error,
                        hasErrors: true,
                        loading: true,
                        submitted: true,
                    };
                }
                case FunFactQuestionActionTypes.IMPORT_FUNFACT_QUESTION: {
                    return {
                        ...state,
                        error: action.payload.error,
                        validationError: action.payload.error,
                        hasErrors: true,
                        loading: true,
                        submitted: true,
                        uploadedQuestions: true,
                    };
                }
                default:
                    return { ...state };
            }
        default:
            return { ...state };
    }
};

export default FunFactQuestion;
