import { APICore } from './apiCore';
const api = new APICore();

// category
function reportedQuestions(params: {
    userId: string,
    questionId: string,
    questionType: string,
    sortBy: string,
    limit: number,
    page: number,
}) {
    const baseUrl = `/reportIssue/question`;
    return api.get(`${baseUrl}`, params);

}




export { reportedQuestions };
