export enum FunFactQuestionActionTypes {
    API_RESPONSE_SUCCESS = '@@funFactQuestion/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR = '@@funFactQuestion/API_RESPONSE_ERROR',

    FUNFACT_QUESTION_LIST = '@@funFactQuestion/FUNFACT_QUESTION_LIST',
    ADD_FUNFACT_QUESTION = '@@funFactQuestion/ADD_FUNFACT_QUESTION',
    SHOW_FUNFACT_QUESTION = '@@funFactQuestion/SHOW_FUNFACT_QUESTION',
    UPDATE_FUNFACT_QUESTION = '@@funFactQuestion/UPDATE_FUNFACT_QUESTION',
    DELETE_FUNFACT_QUESTION = '@@funFactQuestion/DELETE_FUNFACT_QUESTION',
    IMPORT_FUNFACT_QUESTION = '@@funFactQuestion/IMPORT_FUNFACT_QUESTION',
}
