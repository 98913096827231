// constants
import { QuizQuestionActionTypes } from './constants';

export interface QuizQuestionActionType {
    type:
    | QuizQuestionActionTypes.API_RESPONSE_SUCCESS
    | QuizQuestionActionTypes.API_RESPONSE_ERROR
    | QuizQuestionActionTypes.QUIZ_QUESTION_LIST
    | QuizQuestionActionTypes.ADD_QUIZ_QUESTION
    | QuizQuestionActionTypes.UPDATE_QUIZ_QUESTION
    | QuizQuestionActionTypes.SHOW_QUIZ_QUESTION
    | QuizQuestionActionTypes.DELETE_QUIZ_QUESTION
    | QuizQuestionActionTypes.IMPORT_QUIZ_QUESTION
    payload: {} | string;
}


// common success
export const quizQuestionApiResponseSuccess = (actionType: string, data: any, page: number = 0, limit: number = 0, totalPages: number = 0, totalResults: number = 0, categories: any = [], quizQuestionFile: string = ''): QuizQuestionActionType => ({
    type: QuizQuestionActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data, page, limit, totalPages, totalResults, categories, quizQuestionFile },
});
// common error
export const quizQuestionApiResponseError = (actionType: string, error: string): QuizQuestionActionType => ({
    type: QuizQuestionActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const addQuizQuestion = (question: string, media: string, type: string, category: string, options: Array<{ option: string, selected: boolean }>, level: string, regions: any): QuizQuestionActionType => ({
    type: QuizQuestionActionTypes.ADD_QUIZ_QUESTION,
    payload: { question, media, type, category, options, level, regions },
});
interface FiltersData {
    question: string;
    regions: string;
    type: string;
    category: string;
    sortBy: string;
    limit: number;
    page: number;
};
interface ImportFromExcelData {
    regions: string;
    category: string;
    file: File;

};
export const getQuizQuestion = ({
    question,
    regions,
    category,
    sortBy,
    type,
    limit,
    page,
}: FiltersData): QuizQuestionActionType => ({
    type: QuizQuestionActionTypes.QUIZ_QUESTION_LIST,
    payload: {
        question,
        regions,
        category,
        sortBy,
        type,
        limit,
        page,
    },
});

export const showQuizQuestion = (id: string): QuizQuestionActionType => ({
    type: QuizQuestionActionTypes.SHOW_QUIZ_QUESTION,
    payload: { id },
});

export const deleteQuizQuestion = (id: string): QuizQuestionActionType => ({
    type: QuizQuestionActionTypes.DELETE_QUIZ_QUESTION,
    payload: { id },
});

export const updateQuizQuestion = (id: string, question: string, media: string, type: string, category: string, options: Array<{ option: string, selected: boolean }>, level: string, regions: Array<any>, updateMedia: boolean): QuizQuestionActionType => ({
    type: QuizQuestionActionTypes.UPDATE_QUIZ_QUESTION,
    payload: { id, media, question, type, category, options, level, regions, updateMedia },
});

export const importQuizQuestions = ({
    regions,
    category,
    file,
}: ImportFromExcelData): QuizQuestionActionType => ({
    type: QuizQuestionActionTypes.IMPORT_QUIZ_QUESTION,
    payload: {
        regions,
        category,
        file,
    },
});




