// constants
import { QuizActionTypes } from './constants';

export interface QuizActionType {
    type:
    | QuizActionTypes.API_RESPONSE_SUCCESS
    | QuizActionTypes.API_RESPONSE_ERROR
    | QuizActionTypes.QUIZ_LIST
    | QuizActionTypes.ADD_QUIZ
    | QuizActionTypes.UPDATE_QUIZ
    | QuizActionTypes.SHOW_QUIZ
    | QuizActionTypes.DELETE_QUIZ
    payload: {} | string;
}


// common success
export const quizApiResponseSuccess = (actionType: string, data: any, page: number = 0, limit: number = 0, totalPages: number = 0, totalResults: number = 0, categories: any = []): QuizActionType => ({
    type: QuizActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data, page, limit, totalPages, totalResults, categories },
});
// common error
export const quizApiResponseError = (actionType: string, error: string): QuizActionType => ({
    type: QuizActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

interface QuizData {
    name: string,
    region: string,
    quizImage: string,
    category: string,
    joining_fee: number,
    lifeLineCharge: number,
    duration: number,
    marking: number,
    negative_marking: number,
    pool_size: number,
    status: string,
    quiz_question_count: number,
    questions: Array<string>,
    winCoins: number,
}

export const addQuiz = ({
    name,
    region,
    quizImage,
    category,
    joining_fee,
    duration,
    marking,
    negative_marking,
    lifeLineCharge,
    pool_size,
    quiz_question_count,
    questions,
    winCoins,
}: QuizData
): QuizActionType => ({
    type: QuizActionTypes.ADD_QUIZ,
    payload: {
        name,
        region,
        quizImage,
        category,
        joining_fee,
        lifeLineCharge,
        duration,
        marking,
        negative_marking,
        pool_size,
        quiz_question_count,
        questions,
        winCoins,
    },
});

interface FiltersData {
    name: string,
    status: string,
    region: string,
    category: any,
    sortBy: string,
    limit: number,
    page: number,
};

export const getQuiz = ({
    name,
    status,
    region,
    category,
    sortBy,
    limit,
    page,
}: FiltersData): QuizActionType => ({
    type: QuizActionTypes.QUIZ_LIST,
    payload: {
        name,
        status,
        region,
        category,
        sortBy,
        limit,
        page,
    },
});

export const showQuiz = (id: string): QuizActionType => ({
    type: QuizActionTypes.SHOW_QUIZ,
    payload: { id },
});

export const deleteQuiz = (id: string): QuizActionType => ({
    type: QuizActionTypes.DELETE_QUIZ,
    payload: { id },
});

export const updateQuiz = (
    id: string,
    status: string,
): QuizActionType => ({
    type: QuizActionTypes.UPDATE_QUIZ,
    payload: {
        id,
        status,
    },
});





