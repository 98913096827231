import { APICore } from './apiCore';
const api = new APICore();

// quiz
// eslint-disable-next-line no-empty-pattern


function quizzes(params: {
    name: string,
    status: string,
    region: string,
    category: string,
    sortBy: string,
    limit: number,
    page: number,
}) {
    const baseUrl = `/quiz`;
    return api.get(`${baseUrl}`, params);

}
function addQuiz(params: {
    name: string,
    region: string,
    quizImage: string,
    category: string,
    joining_fee: number,
    duration: number,
    marking: number,
    lifeLineCharge: number,
    negative_marking: number,
    pool_size: number,
    winCoins: number,
    quiz_question_count: number,
    questions: Array<string>,
}) {
    const baseUrl = `/quiz`;
    return api.create(`${baseUrl}`, params);

}
function updateQuiz(params: {
    status: string,
}, id: string) {
    const baseUrl = `/quiz/${id}`;
    return api.updatePatch(`${baseUrl}`, params);

}

function deleteQuiz(id: string) {
    const baseUrl = `/quiz/${id}`;
    return api.delete(`${baseUrl}`);

}

function uploadQuizFile(params: { file: File[] }) {
    const baseUrl = `/quiz/uploadQuizImage`;
    return api.uploadFile(`${baseUrl}`, params);
}



export { quizzes, addQuiz, updateQuiz, deleteQuiz, uploadQuizFile };
