import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';

// helpers
import {
    categories as categoriesApi,
    addCategory as addCategoryApi,
    updateCategory as updateCategoryApi,
    deleteCategory as deleteCategoryApi,
} from '../../helpers';

// actions
import { categoryApiResponseSuccess, categoryApiResponseError } from './actions';

// constants
import { CategoryActionTypes } from './constants';

interface CategoryData {
    payload: {
        id: string;
        name: string;
        description: string;
        status: string;
        image: string;
        sortBy: string,
        limit: number,
        page: number,
        updateImage: boolean;
    };
    type: string;
}

/**
 * Category list
 */
function* categoryList({ payload: {
    name,
    status,
    sortBy,
    limit,
    page
} }: CategoryData): SagaIterator {
    try {

        const response = yield call(categoriesApi, {
            name,
            status,
            sortBy,
            limit,
            page
        });

        yield put(categoryApiResponseSuccess(
            CategoryActionTypes.CATEGORY_LIST,
            response.data.results,
            response.data.page,
            response.data.limit,
            response.data.totalPages,
            response.data.totalResults,
        ));
    } catch (error: any) {
        yield put(categoryApiResponseError(CategoryActionTypes.CATEGORY_LIST, error));

    }
}

/**
 * Add Category
 */
function* addCategory({ payload: { name, description, image } }: CategoryData): SagaIterator {
    try {

        const response = yield call(addCategoryApi, { name, description, image });

        yield put(categoryApiResponseSuccess(
            CategoryActionTypes.ADD_CATEGORY,
            response.data.data
        ));
    } catch (error: any) {
        yield put(categoryApiResponseError(CategoryActionTypes.ADD_CATEGORY, error));

    }
}

/**
 * Update Category
 */
function* updateCategory({ payload: { id, name, description, status, image, updateImage } }: CategoryData): SagaIterator {
    try {

        const response = yield call(updateCategoryApi, { name, description, status, image, updateImage }, id);

        yield put(categoryApiResponseSuccess(
            CategoryActionTypes.UPDATE_CATEGORY,
            response.data.data
        ));
    } catch (error: any) {
        yield put(categoryApiResponseError(CategoryActionTypes.UPDATE_CATEGORY, error));

    }
}
/**
 * Delete Category
 */
function* deleteCategory({ payload: { id } }: CategoryData): SagaIterator {
    try {

        yield call(deleteCategoryApi, id);
        yield put(categoryApiResponseSuccess(
            CategoryActionTypes.DELETE_CATEGORY,
            id
        ));
    } catch (error: any) {
        yield put(categoryApiResponseError(CategoryActionTypes.DELETE_CATEGORY, error));

    }
}



export function* watchCategoryList() {
    yield takeEvery(CategoryActionTypes.CATEGORY_LIST, categoryList);
}

export function* watchAddCategory() {
    yield takeEvery(CategoryActionTypes.ADD_CATEGORY, addCategory);
}

export function* watchUpdateCategory() {
    yield takeEvery(CategoryActionTypes.UPDATE_CATEGORY, updateCategory);
}

export function* watchDeleteCategory() {
    yield takeEvery(CategoryActionTypes.DELETE_CATEGORY, deleteCategory);
}



function* categorySaga() {
    yield all([fork(watchCategoryList), fork(watchAddCategory), fork(watchUpdateCategory), fork(watchDeleteCategory)]);
}

export default categorySaga;
