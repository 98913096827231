import { APICore } from './apiCore';
const api = new APICore();

// category
function categories(params: {
    name: string,
    status: string,
    sortBy: string,
    limit: number,
    page: number,
}) {
    const baseUrl = `/category`;
    return api.get(`${baseUrl}`, params);

}
function addCategory(params: { name: string, description: string, image: string }) {
    const baseUrl = `/category`;
    return api.create(`${baseUrl}`, params);

}
function updateCategory(params: { name: string, description: string, status: string, image: string, updateImage: boolean }, id: string) {
    const baseUrl = `/category/${id}`;
    return api.updatePatch(`${baseUrl}`, params);

}
function deleteCategory(id: string) {
    const baseUrl = `/category/${id}`;
    return api.delete(`${baseUrl}`);

}



export { categories, addCategory, updateCategory, deleteCategory };
