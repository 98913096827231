// constants
import { DashboardActionTypes } from './constants';

const INIT_STATE = {
    userData: {},
    contestData: {},
    quizData: {},
    predictionData: {},
    supportData: {},
    questionData: {},
    categoriesCount: {},
    gameTraffic: {},
    userGraphData: [],
    loading: false,
};

interface DashboardActionType {
    type:
    | DashboardActionTypes.API_RESPONSE_SUCCESS
    | DashboardActionTypes.API_RESPONSE_ERROR
    | DashboardActionTypes.DASHBOARD_DATA
    | DashboardActionTypes.USER_GRAPH_DATA
    payload: {
        actionType?: string;
        userData: Object;
        contestData: Object;
        quizData: Object;
        predictionData: Object;
        supportData: Object;
        questionData: Object;
        categoriesCount: Object;
        gameTraffic: Object;
        userGraphData: Array<any>;
        error?: any;
    };
}

interface State {
    userData: Object;
    contestData: Object;
    quizData: Object;
    predictionData: Object;
    supportData: Object;
    questionData: Object;
    categoriesCount: Object;
    userGraphData: Array<any>;
    gameTraffic: Object;
    loading: boolean,
}

const Dashboard = (state: State = INIT_STATE, action: DashboardActionType): any => {
    switch (action.type) {
        case DashboardActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case DashboardActionTypes.DASHBOARD_DATA: {
                    return {
                        ...state,
                        userData: action.payload.userData,
                        contestData: action.payload.contestData,
                        quizData: action.payload.quizData,
                        predictionData: action.payload.predictionData,
                        supportData: action.payload.supportData,
                        questionData: action.payload.questionData,
                        categoriesCount: action.payload.categoriesCount,
                        userGraphData: action.payload.userGraphData,
                        gameTraffic: action.payload.gameTraffic,
                        loading: true,
                    };
                }
                case DashboardActionTypes.USER_GRAPH_DATA: {
                    return {
                        ...state,
                        userGraphData: action.payload.userGraphData,
                        loading: true,
                    };
                }
                default:
                    return { ...state };
            }

        case DashboardActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case DashboardActionTypes.DASHBOARD_DATA: {
                    return {
                        ...state,
                        error: action.payload.error,
                        hasErrors: true,
                        loading: true,
                        submitted: false,
                    };
                }
                case DashboardActionTypes.USER_GRAPH_DATA: {
                    return {
                        ...state,
                        error: action.payload.error,
                        hasErrors: true,
                        loading: true,
                        submitted: false,
                    };
                }
                default:
                    return { ...state };
            }
        default:
            return { ...state };
    }
};

export default Dashboard;
