// constants
import { ContestQuestionActionTypes } from './constants';

export interface ContestQuestionActionType {
    type:
    | ContestQuestionActionTypes.API_RESPONSE_SUCCESS
    | ContestQuestionActionTypes.API_RESPONSE_ERROR
    | ContestQuestionActionTypes.CONTEST_QUESTION_LIST
    | ContestQuestionActionTypes.ADD_CONTEST_QUESTION
    | ContestQuestionActionTypes.UPDATE_CONTEST_QUESTION
    | ContestQuestionActionTypes.SHOW_CONTEST_QUESTION
    | ContestQuestionActionTypes.DELETE_CONTEST_QUESTION
    | ContestQuestionActionTypes.IMPORT_CONTEST_QUESTION
    payload: {} | string;
}


// common success
export const contestQuestionApiResponseSuccess = (actionType: string, data: any, page: number = 0, limit: number = 0, totalPages: number = 0, totalResults: number = 0, categories: any = [], contestQuestionFile: string = '', winnerCoinRanks: any = []): ContestQuestionActionType => ({
    type: ContestQuestionActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data, page, limit, totalPages, totalResults, categories, contestQuestionFile, winnerCoinRanks },
});
// common error
export const contestQuestionApiResponseError = (actionType: string, error: string): ContestQuestionActionType => ({
    type: ContestQuestionActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const addContestQuestion = (question: string, media: string, type: string, category: string, options: Array<{ option: string, selected: boolean }>, level: string, regions: any): ContestQuestionActionType => ({
    type: ContestQuestionActionTypes.ADD_CONTEST_QUESTION,
    payload: { question, media, type, category, options, level, regions },
});
interface FiltersData {
    question: string;
    regions: string;
    type: string;
    category: string;
    sortBy: string;
    limit: number;
    page: number;
};
interface ImportFromExcelData {
    regions: string;
    category: string;
    file: File;

};
export const getContestQuestion = ({
    question,
    regions,
    category,
    sortBy,
    type,
    limit,
    page,
}: FiltersData): ContestQuestionActionType => ({
    type: ContestQuestionActionTypes.CONTEST_QUESTION_LIST,
    payload: {
        question,
        regions,
        category,
        sortBy,
        type,
        limit,
        page,
    },
});

export const showContestQuestion = (id: string): ContestQuestionActionType => ({
    type: ContestQuestionActionTypes.SHOW_CONTEST_QUESTION,
    payload: { id },
});

export const deleteContestQuestion = (id: string): ContestQuestionActionType => ({
    type: ContestQuestionActionTypes.DELETE_CONTEST_QUESTION,
    payload: { id },
});

export const updateContestQuestion = (id: string, question: string, media: string, type: string, category: string, options: Array<{ option: string, selected: boolean }>, level: string, regions: Array<any>, updateMedia: boolean): ContestQuestionActionType => ({
    type: ContestQuestionActionTypes.UPDATE_CONTEST_QUESTION,
    payload: { id, media, question, type, category, options, level, regions, updateMedia },
});

export const importContestQuestions = ({
    regions,
    category,
    file,
}: ImportFromExcelData): ContestQuestionActionType => ({
    type: ContestQuestionActionTypes.IMPORT_CONTEST_QUESTION,
    payload: {
        regions,
        category,
        file,
    },
});




