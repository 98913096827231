// constants
import { DashboardActionTypes } from './constants';

export interface DashboardActionType {
    type:
    | DashboardActionTypes.API_RESPONSE_SUCCESS
    | DashboardActionTypes.API_RESPONSE_ERROR
    | DashboardActionTypes.DASHBOARD_DATA
    | DashboardActionTypes.USER_GRAPH_DATA

    payload: {} | string;
}


// common success
export const dashboardApiResponseSuccess = (actionType: string,
    userData: Object,
    contestData: Object,
    quizData: Object,
    predictionData: Object,
    supportData: Object,
    questionData: Object,
    categoriesCount: Object,
    userGraphData: Array<any>,
    gameTraffic: Object,
): DashboardActionType => ({
    type: DashboardActionTypes.API_RESPONSE_SUCCESS,
    payload: {
        actionType,
        userData,
        contestData,
        quizData,
        predictionData,
        supportData,
        questionData,
        categoriesCount,
        userGraphData,
        gameTraffic,
    },
});
// common error
export const dashboardApiResponseError = (actionType: string, error: string): DashboardActionType => ({
    type: DashboardActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});


export const getDashboard = (): DashboardActionType => ({
    type: DashboardActionTypes.DASHBOARD_DATA,
    payload: {},
});
export const getUserGraphData = (range: string, userType: string): DashboardActionType => ({
    type: DashboardActionTypes.USER_GRAPH_DATA,
    payload: { range, userType },
});







