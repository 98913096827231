// constants
import { PredictionQuestionActionTypes } from './constants';

export interface PredictionQuestionActionType {
    type:
    | PredictionQuestionActionTypes.API_RESPONSE_SUCCESS
    | PredictionQuestionActionTypes.API_RESPONSE_ERROR
    | PredictionQuestionActionTypes.PREDICTION_QUESTION_LIST
    | PredictionQuestionActionTypes.ADD_PREDICTION_QUESTION
    | PredictionQuestionActionTypes.UPDATE_PREDICTION_QUESTION
    | PredictionQuestionActionTypes.SHOW_PREDICTION_QUESTION
    | PredictionQuestionActionTypes.DELETE_PREDICTION_QUESTION
    | PredictionQuestionActionTypes.IMPORT_PREDICTION_QUESTION
    payload: {} | string;
}


// common success
export const predictionQuestionApiResponseSuccess = (actionType: string, data: any, page: number = 0, limit: number = 0, totalPages: number = 0, totalResults: number = 0, categories: any = [], predictionQuestionFile: string = ''): PredictionQuestionActionType => ({
    type: PredictionQuestionActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data, page, limit, totalPages, totalResults, categories, predictionQuestionFile },
});
// common error
export const predictionQuestionApiResponseError = (actionType: string, error: string): PredictionQuestionActionType => ({
    type: PredictionQuestionActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const addPredictionQuestion = (question: string, media: string, type: string, category: string, options: Array<{ option: string, selected: boolean }>, level: string, regions: any): PredictionQuestionActionType => ({
    type: PredictionQuestionActionTypes.ADD_PREDICTION_QUESTION,
    payload: { question, media, type, category, options, level, regions },
});

interface FiltersData {
    question: string;
    regions: string;
    type: string;
    correctOption: any;
    category: string;
    sortBy: string;
    limit: number;
    page: number;
};

interface ImportFromExcelData {
    regions: string;
    category: string;
    file: File;

};
export const getPredictionQuestion = ({
    question,
    regions,
    category,
    sortBy,
    correctOption,
    type,
    limit,
    page,
}: FiltersData): PredictionQuestionActionType => ({
    type: PredictionQuestionActionTypes.PREDICTION_QUESTION_LIST,
    payload: {
        question,
        regions,
        category,
        correctOption,
        sortBy,
        type,
        limit,
        page,
    },
});

export const showPredictionQuestion = (id: string): PredictionQuestionActionType => ({
    type: PredictionQuestionActionTypes.SHOW_PREDICTION_QUESTION,
    payload: { id },
});

export const deletePredictionQuestion = (id: string): PredictionQuestionActionType => ({
    type: PredictionQuestionActionTypes.DELETE_PREDICTION_QUESTION,
    payload: { id },
});

export const updatePredictionQuestion = (id: string, question: string, media: string, type: string, category: string, options: Array<{ option: string, selected: boolean }>, level: string, regions: Array<any>, updateMedia: boolean): PredictionQuestionActionType => ({
    type: PredictionQuestionActionTypes.UPDATE_PREDICTION_QUESTION,
    payload: { id, media, question, type, category, options, level, regions, updateMedia },
});

export const importPredictionQuestions = ({
    regions,
    category,
    file,
}: ImportFromExcelData): PredictionQuestionActionType => ({
    type: PredictionQuestionActionTypes.IMPORT_PREDICTION_QUESTION,
    payload: {
        regions,
        category,
        file,
    },
});





