// constants
import { QuizActionTypes } from './constants';

const INIT_STATE = {
    quizzes: [],
    page: 1,
    limit: 50,
    totalPages: 0,
    totalResults: 0,
    categories:[],
    loading: false,
    hasErrors: false,
    submitted: false,
};

interface QuizActionType {
    type:
    | QuizActionTypes.API_RESPONSE_SUCCESS
    | QuizActionTypes.API_RESPONSE_ERROR
    | QuizActionTypes.QUIZ_LIST
    | QuizActionTypes.ADD_QUIZ
    | QuizActionTypes.SHOW_QUIZ
    | QuizActionTypes.UPDATE_QUIZ

    payload: {
        actionType?: string;
        data?: any;
        page?: number;
        limit?: number;
        totalPages?: number;
        totalResults?: number;
        categories?: any;
        error?: any;
        validationError?: { error: string; key: string; }[];
        hasErrors: boolean,
        submitted: boolean,
    };
}

interface State {
    quizzes: any;
    page: number;
    limit: number;
    totalPages: number;
    totalResults: number;
}

const Quiz = (state: State = INIT_STATE, action: QuizActionType): any => {
    switch (action.type) {
        case QuizActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case QuizActionTypes.QUIZ_LIST: {
                    return {
                        ...state,
                        quizzes: action.payload.data,
                        page: action.payload.page,
                        limit: action.payload.limit,
                        totalPages: action.payload.totalPages,
                        totalResults: action.payload.totalResults,
                        categories: action.payload.categories,
                        loading: true,
                        submitted: false,
                    };
                }
                case QuizActionTypes.ADD_QUIZ: {
                    return {
                        ...state,
                        quizzes: [...state.quizzes, action.payload.data],
                        totalResults: (action.payload.totalResults ? (action.payload.totalResults + 1) : 0),
                        hasErrors: false,
                        loading: true,
                        submitted: true,
                    };
                }
                case QuizActionTypes.UPDATE_QUIZ: {
                    const updatedQuiz = action.payload.data;
                    const allQuizs = state.quizzes;
                    const updatedQuizs = allQuizs.map((qq: any) => qq.id === updatedQuiz.id ? updatedQuiz : qq);
                    return {
                        ...state,
                        quizzes: updatedQuizs,
                        hasErrors: false,
                        loading: true,
                        submitted: true,
                    };
                }
                case QuizActionTypes.DELETE_QUIZ: {
                    const deletedId = action.payload.data;
                    const allQuizs = state.quizzes;
                    const updatedQuizs = allQuizs.filter((cat: any) => cat.id !== deletedId);
                    return {
                        ...state,
                        quizzes: updatedQuizs,
                        hasErrors: false,
                        loading: true,
                        submitted: true,
                    };
                }
                default:
                    return { ...state };
            }

        case QuizActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case QuizActionTypes.QUIZ_LIST: {
                    return {
                        ...state,
                        error: action.payload.error,
                        hasErrors: true,
                        loading: true,
                        submitted: false,
                    };
                }
                case QuizActionTypes.ADD_QUIZ: {
                    return {
                        ...state,
                        error: action.payload.error,
                        validationError: action.payload.error,
                        hasErrors: true,
                        loading: true,
                        submitted: true,
                    };
                }
                case QuizActionTypes.UPDATE_QUIZ: {
                    return {
                        ...state,
                        error: action.payload.error,
                        validationError: action.payload.error,
                        hasErrors: true,
                        loading: true,
                        submitted: true,
                    };
                }
                default:
                    return { ...state };
            }
        default:
            return { ...state };
    }
};

export default Quiz;
