import { APICore } from './apiCore';
const api = new APICore();

// funFactQuestion
// eslint-disable-next-line no-empty-pattern
function funFactQuestions(params: {
    question: string,
    sortBy: string,
    limit: number,
    page: number;
}) {
    const baseUrl = `/funFactQuestion`;
    return api.get(`${baseUrl}`, params);

}
function addFunFactQuestion(params: { question: string, options: Array<{ option: string, selected: boolean }>, funFact: string, regions: any }) {
    const baseUrl = `/funFactQuestion`;
    return api.create(`${baseUrl}`, params);

}
function updateFunFactQuestion(params: { question: string, options: Array<{ option: string, selected: boolean }>, funFact: string, regions: any }, id: string) {
    const baseUrl = `/funFactQuestion/${id}`;
    return api.updatePatch(`${baseUrl}`, params);

}

function deleteFunFactQuestion(id: string) {
    const baseUrl = `/funFactQuestion/${id}`;
    return api.delete(`${baseUrl}`);

}

function importFunFactQuestions(params: {
    regions: any,
    file: File
}) {
    const baseUrl = `/funFactQuestion/importQuestions`;
    return api.createWithFile(`${baseUrl}`, params);
}



export { funFactQuestions, addFunFactQuestion, updateFunFactQuestion, deleteFunFactQuestion, importFunFactQuestions };
