// constants
import config from '../../config';
import { QuizQuestionActionTypes } from './constants';

const INIT_STATE = {
    quizQuestions: [],
    page: 1,
    limit: 50,
    totalPages: 0,
    totalResults: 0,
    quizQuestionFile: '',
    loading: false,
    hasErrors: false,
    submitted: false,
    uploadedQuestions: false,
};

interface QuizQuestionActionType {
    type:
    | QuizQuestionActionTypes.API_RESPONSE_SUCCESS
    | QuizQuestionActionTypes.API_RESPONSE_ERROR
    | QuizQuestionActionTypes.QUIZ_QUESTION_LIST
    | QuizQuestionActionTypes.ADD_QUIZ_QUESTION
    | QuizQuestionActionTypes.SHOW_QUIZ_QUESTION
    | QuizQuestionActionTypes.UPDATE_QUIZ_QUESTION

    payload: {
        actionType?: string;
        data?: any;
        page?: number;
        limit?: number;
        totalPages?: number;
        totalResults?: number;
        categories?: any;
        quizQuestionFile?: string;
        error?: any;
        validationError?: { error: string; key: string; }[];
        hasErrors: boolean,
        submitted: boolean,
        uploadedQuestions: boolean,
    };
}

interface State {
    quizQuestions: any;
    page: number;
    limit: number;
    totalPages: number;
    totalResults: number;
    quizQuestionFile: string;
}

const QuizQuestion = (state: State = INIT_STATE, action: QuizQuestionActionType): any => {
    switch (action.type) {
        case QuizQuestionActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case QuizQuestionActionTypes.QUIZ_QUESTION_LIST: {
                    return {
                        ...state,
                        quizQuestions: action.payload.data,
                        page: action.payload.page,
                        limit: action.payload.limit,
                        totalPages: action.payload.totalPages,
                        totalResults: action.payload.totalResults,
                        categories: action.payload.categories,
                        loading: true,
                        submitted: false,
                        uploadedQuestions: false,
                    };
                }
                case QuizQuestionActionTypes.ADD_QUIZ_QUESTION: {
                    return {
                        ...state,
                        quizQuestions: [...state.quizQuestions, action.payload.data],
                        totalResults: (action.payload.totalResults ? (action.payload.totalResults + 1) : 0),
                        hasErrors: false,
                        loading: true,
                        submitted: true,
                        uploadedQuestions: false,
                    };
                }
                case QuizQuestionActionTypes.UPDATE_QUIZ_QUESTION: {
                    const updatedQuizQuestion = action.payload.data;
                    const allQuizQuestions = state.quizQuestions;
                    const updatedQuizQuestions = allQuizQuestions.map((qq: any) => qq.id === updatedQuizQuestion.id ? updatedQuizQuestion : qq);
                    return {
                        ...state,
                        quizQuestions: updatedQuizQuestions,
                        hasErrors: false,
                        loading: true,
                        submitted: true,
                        uploadedQuestions: false,
                    };
                }
                case QuizQuestionActionTypes.DELETE_QUIZ_QUESTION: {
                    const deletedId = action.payload.data;
                    const allQuizQuestions = state.quizQuestions;
                    const updatedQuizQuestions = allQuizQuestions.filter((cat: any) => cat.id !== deletedId);
                    return {
                        ...state,
                        quizQuestions: updatedQuizQuestions,
                        hasErrors: false,
                        loading: true,
                        submitted: false,
                        uploadedQuestions: false,
                    };
                }
                case QuizQuestionActionTypes.IMPORT_QUIZ_QUESTION: {
                    const fileData = action.payload.data;
                    var FileSaver = require('file-saver');
                    const file = `${config.EXCEL_URL}/${fileData}`
                    FileSaver.saveAs(file, "uploadedQuestion.xlsx", { autoBom: true });
                    return {
                        ...state,
                        hasErrors: false,
                        loading: true,
                        submitted: false,
                        uploadedQuestions: true,
                    };
                }
                default:
                    return { ...state };
            }

        case QuizQuestionActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case QuizQuestionActionTypes.QUIZ_QUESTION_LIST: {
                    return {
                        ...state,
                        error: action.payload.error,
                        hasErrors: true,
                        loading: true,
                        submitted: false,
                    };
                }
                case QuizQuestionActionTypes.ADD_QUIZ_QUESTION: {
                    return {
                        ...state,
                        error: action.payload.error,
                        validationError: action.payload.error,
                        hasErrors: true,
                        loading: true,
                        submitted: true,
                    };
                }
                case QuizQuestionActionTypes.UPDATE_QUIZ_QUESTION: {
                    return {
                        ...state,
                        error: action.payload.error,
                        validationError: action.payload.error,
                        hasErrors: true,
                        loading: true,
                        submitted: true,
                    };
                }
                case QuizQuestionActionTypes.DELETE_QUIZ_QUESTION: {
                    return {
                        ...state,
                        error: action.payload.error,
                        validationError: action.payload.error,
                        hasErrors: true,
                        loading: true,
                        submitted: true,
                    };
                }
                case QuizQuestionActionTypes.IMPORT_QUIZ_QUESTION: {
                    return {
                        ...state,
                        error: action.payload.error,
                        validationError: action.payload.error,
                        hasErrors: true,
                        loading: true,
                        submitted: true,
                        uploadedQuestions: true,
                    };
                }
                default:
                    return { ...state };
            }
        default:
            return { ...state };
    }
};

export default QuizQuestion;
