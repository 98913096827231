// constants
import { PredictionActionTypes } from './constants';

const INIT_STATE = {
    predictions: [],
    prediction: {},
    page: 1,
    limit: 50,
    totalPages: 0,
    totalResults: 0,
    categories: [],
    loading: false,
    hasErrors: false,
    submitted: false,
};

interface PredictionActionType {
    type:
    | PredictionActionTypes.API_RESPONSE_SUCCESS
    | PredictionActionTypes.API_RESPONSE_ERROR
    | PredictionActionTypes.PREDICTION_LIST
    | PredictionActionTypes.ADD_PREDICTION
    | PredictionActionTypes.SHOW_PREDICTION
    | PredictionActionTypes.UPDATE_PREDICTION
    | PredictionActionTypes.UPDATE_ANSWER

    payload: {
        actionType?: string;
        data?: any;
        page?: number;
        limit?: number;
        totalPages?: number;
        totalResults?: number;
        categories?: any;
        error?: any;
        validationError?: { error: string; key: string; }[];
        hasErrors: boolean,
        submitted: boolean,
    };
}

interface State {
    predictions: any;
    prediction: any;
    page: number;
    limit: number;
    totalPages: number;
    totalResults: number;
}

const Prediction = (state: State = INIT_STATE, action: PredictionActionType): any => {
    switch (action.type) {
        case PredictionActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case PredictionActionTypes.PREDICTION_LIST: {
                    return {
                        ...state,
                        predictions: action.payload.data,
                        page: action.payload.page,
                        limit: action.payload.limit,
                        totalPages: action.payload.totalPages,
                        totalResults: action.payload.totalResults,
                        categories: action.payload.categories,
                        loading: true,
                        submitted: false,
                    };
                }
                case PredictionActionTypes.ADD_PREDICTION: {
                    return {
                        ...state,
                        predictions: [...state.predictions, action.payload.data],
                        totalResults: (action.payload.totalResults ? (action.payload.totalResults + 1) : 0),
                        hasErrors: false,
                        loading: true,
                        submitted: true,
                    };
                }
                case PredictionActionTypes.UPDATE_PREDICTION: {
                    const updatedPrediction = action.payload.data;
                    const allPredictions = state.predictions;
                    const updatedPredictions = allPredictions.map((qq: any) => qq.id === updatedPrediction.id ? updatedPrediction : qq);
                    return {
                        ...state,
                        predictions: updatedPredictions,
                        hasErrors: false,
                        loading: true,
                        submitted: true,
                    };
                }
                case PredictionActionTypes.UPDATE_PREDICTION_STATUS: {
                    const updatedPrediction = action.payload.data;
                    const allPredictions = state.predictions;
                    const updatedPredictions = allPredictions.map((qq: any) => qq.id === updatedPrediction.id ? updatedPrediction : qq);
                    return {
                        ...state,
                        predictions: updatedPredictions,
                        hasErrors: false,
                        loading: true,
                        submitted: true,
                    };
                }
                case PredictionActionTypes.SHOW_PREDICTION: {
                    const updatedPrediction = action.payload.data;
                    return {
                        ...state,
                        prediction: updatedPrediction,
                        categories: action.payload.categories,
                        hasErrors: false,
                        loading: true,
                        submitted: false,
                    };
                }
                case PredictionActionTypes.DELETE_PREDICTION: {
                    const deletedId = action.payload.data;
                    const allPredictions = state.predictions;
                    const updatedPredictions = allPredictions.filter((cat: any) => cat.id !== deletedId);
                    return {
                        ...state,
                        predictions: updatedPredictions,
                        hasErrors: false,
                        loading: true,
                        submitted: true,
                    };
                }
                case PredictionActionTypes.UPDATE_ANSWER: {
                    const prediction = state.prediction;
                    const updatedQuestions = prediction.questions.map((question: any) => question.id === action.payload.data.id ? action.payload.data : question);
                    const updatedPrediction =  {...prediction,questions:updatedQuestions};
                    return {
                        ...state,
                        hasErrors: false,
                        prediction:updatedPrediction,
                        loading: true,
                        submitted: true,
                    };
                }
                default:
                    return { ...state };
            }

        case PredictionActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case PredictionActionTypes.PREDICTION_LIST: {
                    return {
                        ...state,
                        error: action.payload.error,
                        hasErrors: true,
                        loading: true,
                        submitted: false,
                    };
                }
                case PredictionActionTypes.ADD_PREDICTION: {
                    return {
                        ...state,
                        error: action.payload.error,
                        validationError: action.payload.error,
                        hasErrors: true,
                        loading: true,
                        submitted: true,
                    };
                }
                case PredictionActionTypes.UPDATE_PREDICTION: {
                    return {
                        ...state,
                        error: action.payload.error,
                        validationError: action.payload.error,
                        hasErrors: true,
                        loading: true,
                        submitted: true,
                    };
                }
                case PredictionActionTypes.UPDATE_PREDICTION_STATUS: {
                    return {
                        ...state,
                        error: action.payload.error,
                        validationError: action.payload.error,
                        hasErrors: true,
                        loading: true,
                        submitted: true,
                    };
                }
                case PredictionActionTypes.SHOW_PREDICTION: {
                    return {
                        ...state,
                        error: action.payload.error,
                        validationError: action.payload.error,
                        hasErrors: true,
                        loading: true,
                        submitted: false,
                    };
                }
                case PredictionActionTypes.UPDATE_ANSWER: {
                    return {
                        ...state,
                        error: action.payload.error,
                        validationError: action.payload.error,
                        hasErrors: true,
                        loading: true,
                        submitted: true,
                    };
                }
                default:
                    return { ...state };
            }
        default:
            return { ...state };
    }
};

export default Prediction;
