import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';

// helpers
import {
    quizzes as quizzesApi,
    addQuiz as addQuizApi,
    updateQuiz as updateQuizApi,
    deleteQuiz as deleteQuizApi,
} from '../../helpers';

// actions
import { quizApiResponseSuccess, quizApiResponseError } from './actions';

// constants
import { QuizActionTypes } from './constants';

interface QuizData {
    payload: {
        id: string,
        name: string,
        region: string,
        quizImage: string,
        category: string,
        joining_fee: number,
        duration: number,
        lifeLineCharge: number,
        marking: number,
        negative_marking: number,
        pool_size: number,
        quiz_question_count: number,
        questions: Array<string>,
        updateImage: string,
        status: string,
        sortBy: string,
        page: number,
        limit: number,
        winCoins: number,
    };
    type: string;
}

/**
 * Quiz list
 */
function* quizList({ payload: {
    name,
    status,
    region,
    category,
    sortBy,
    limit,
    page 
} }: QuizData): SagaIterator {
    try {

        const response = yield call(quizzesApi, {
            name,
            status,
            region,
            category,
            sortBy,
            limit,
            page
        });
        yield put(quizApiResponseSuccess(
            QuizActionTypes.QUIZ_LIST,
            response.data.results,
            response.data.page,
            response.data.limit,
            response.data.totalPages,
            response.data.totalResults,
            response.data.categories,
        ));
    } catch (error: any) {
        yield put(quizApiResponseError(QuizActionTypes.QUIZ_LIST, error));

    }
}

/**
 * Add Quiz
 */
function* addQuiz({ payload: {
    name,
    region,
    quizImage,
    category,
    joining_fee,
    duration,
    lifeLineCharge,
    marking,
    negative_marking,
    pool_size,
    quiz_question_count,
    questions,
    winCoins
} }: QuizData): SagaIterator {
    try {

        const response = yield call(addQuizApi, {
            name,
            region,
            quizImage,
            category,
            joining_fee,
            duration,
            marking,
            negative_marking,
            lifeLineCharge,
            pool_size,
            quiz_question_count,
            questions,
            winCoins,
        });

        yield put(quizApiResponseSuccess(
            QuizActionTypes.ADD_QUIZ,
            response.data.data
        ));
    } catch (error: any) {
        yield put(quizApiResponseError(QuizActionTypes.ADD_QUIZ, error));

    }
}

/**
 * Update Quiz
 */
function* updateQuiz({ payload: {
    id,
    status,
   
} }: QuizData): SagaIterator {
    try {

        const response = yield call(updateQuizApi, {
            status,
        }, id);

        yield put(quizApiResponseSuccess(
            QuizActionTypes.UPDATE_QUIZ,
            response.data.data
        ));
    } catch (error: any) {
        yield put(quizApiResponseError(QuizActionTypes.UPDATE_QUIZ, error));

    }
}
/**
 * Delete Quiz
 */
function* deleteQuiz({ payload: { id } }: QuizData): SagaIterator {
    try {

        yield call(deleteQuizApi, id);
        yield put(quizApiResponseSuccess(
            QuizActionTypes.DELETE_QUIZ,
            id
        ));
    } catch (error: any) {
        yield put(quizApiResponseError(QuizActionTypes.DELETE_QUIZ, error));

    }
}




export function* watchQuizList() {
    yield takeEvery(QuizActionTypes.QUIZ_LIST, quizList);
}

export function* watchAddQuiz() {
    yield takeEvery(QuizActionTypes.ADD_QUIZ, addQuiz);
}

export function* watchUpdateQuiz() {
    yield takeEvery(QuizActionTypes.UPDATE_QUIZ, updateQuiz);
}

export function* watchDeleteQuiz() {
    yield takeEvery(QuizActionTypes.DELETE_QUIZ, deleteQuiz);
}




function* quizSaga() {
    yield all([fork(watchQuizList), fork(watchAddQuiz), fork(watchUpdateQuiz), fork(watchDeleteQuiz)]);
}

export default quizSaga;
