import React from 'react';
import { Route, RouteProps } from 'react-router-dom';

// components
import PrivateRoute from './PrivateRoute';
import Root from './Root';

// lazy load all the views

// auth
const Login = React.lazy(() => import('../pages/auth/Login'));
const Logout = React.lazy(() => import('../pages/auth/Logout'));
const Confirm = React.lazy(() => import('../pages/auth/Confirm'));
const ForgetPassword = React.lazy(() => import('../pages/auth/ForgetPassword'));

const EcommerceDashboard = React.lazy(() => import('../pages/dashboard/Ecommerce/'));


// Category
const Category = React.lazy(() => import('../pages/apps/Category'));

// QuizQuestionBank
const QuizQuestionBank = React.lazy(() => import('../pages/apps/QuizQuestionBank'));
// ContextQuestionBank
const ContestQuestionBank = React.lazy(() => import('../pages/apps/ContestQuestionBank'));
// PredictionQuestionBank
const PredictionQuestionBank = React.lazy(() => import('../pages/apps/PredictionQuestionBank'));

// FunFactQuestionBank
const FunFactQuestionBank = React.lazy(() => import('../pages/apps/FunFactQuestionBank'));

// Quiz
const QuizList = React.lazy(() => import('../pages/apps/Quiz/List'));
const CreateQuiz = React.lazy(() => import('../pages/apps/Quiz/Add'));

// Contest
const ContestList = React.lazy(() => import('../pages/apps/Contest/List'));
const CreateContest = React.lazy(() => import('../pages/apps/Contest/Add'));
const EditContest = React.lazy(() => import('../pages/apps/Contest/Edit'));

// Contest
const PredictionList = React.lazy(() => import('../pages/apps/Prediction/List'));
const CreatePrediction = React.lazy(() => import('../pages/apps/Prediction/Add'));
const EditPrediction = React.lazy(() => import('../pages/apps/Prediction/Edit'));
const UpdatePredictionAnswers = React.lazy(() => import('../pages/apps/Prediction/UpdateAnswers'));

// Contest
const UserList = React.lazy(() => import('../pages/apps/User/List'));
const UserProfile = React.lazy(() => import('../pages/apps/User/Profile'));

// Reported Issues
const ReportedIssue = React.lazy(() => import('../pages/apps/ReportedIssue/List'));

// Reported Question
const ReportedQuestion = React.lazy(() => import('../pages/apps/ReportedQuestion/List'));

// Reported Question
const WinnerCoinRanks = React.lazy(() => import('../pages/apps/WinnerCoinRank'));






export interface RoutesProps {
    path: RouteProps['path'];
    name?: string;
    component?: RouteProps['component'];
    route?: any;
    exact?: RouteProps['exact'];
    icon?: string;
    header?: string;
    roles?: string[];
    children?: RoutesProps[];
}

// root routes
const rootRoute: RoutesProps = {
    path: '/',
    exact: true,
    component: () => <Root />,
    route: Route,
};

// dashboards
const dashboardRoutes: RoutesProps = {
    path: '/dashboard',
    name: 'Dashboards',
    icon: 'airplay',
    header: 'Navigation',
    component: EcommerceDashboard,
    route: PrivateRoute,
};

// Coin Winner Ranks  
const winnerRanks: RoutesProps = {
    path: '/winnerCoinRanks',
    name: 'Winner Coin ranks',
    icon: 'triangle',
    header: 'Navigation',
    component: WinnerCoinRanks,
    route: PrivateRoute,
};
const usersRoutes: RoutesProps = {
    path: '/user',
    name: 'User',
    route: PrivateRoute,
    roles: ['admin'],
    icon: 'users',
    children: [
        {
            path: '/user/list',
            name: 'List',
            component: UserList,
            route: PrivateRoute,
        },
        {
            path: '/user/detail/:userId',
            name: 'User Profile',
            component: UserProfile,
            route: PrivateRoute,
        },
    ],
};

const reportedIssueRoutes: RoutesProps = {
    path: '/reportedIssue/list',
    name: 'Reported Issues',
    route: PrivateRoute,
    roles: ['admin'],
    icon: 'help-circle',
    component: ReportedIssue,
};

const reportedQuestionRoutes: RoutesProps = {
    path: '/reportedQuestion/list',
    name: 'Reported Question',
    route: PrivateRoute,
    roles: ['admin'],
    icon: 'headphones',
    component: ReportedQuestion,
};

const categoryRoutes: RoutesProps = {
    path: '/category',
    name: 'Category',
    route: PrivateRoute,
    roles: ['admin'],
    icon: 'calendar',
    component: Category,
};

const questionBankRoutes: RoutesProps = {
    path: '/questionBank',
    name: 'Quiz Questions',
    route: PrivateRoute,
    roles: ['admin'],
    icon: 'uil-briefcase',
    children: [
        // {
        //     path: '/questionBank/quiz',
        //     name: 'Quiz',
        //     component: QuizQuestionBank,
        //     route: PrivateRoute,
        // },
        {
            path: '/questionBank/contest',
            name: 'Contest',
            component: ContestQuestionBank,
            route: PrivateRoute,
        },
        {
            path: '/questionBank/prediction',
            name: 'Contest',
            component: PredictionQuestionBank,
            route: PrivateRoute,
        },
        {
            path: '/questionBank/funFact',
            name: 'Fun Fact',
            component: FunFactQuestionBank,
            route: PrivateRoute,
        },
    ],
};

// const quizAppRoutes: RoutesProps = {
//     path: '/quiz',
//     name: 'Quiz',
//     route: PrivateRoute,
//     roles: ['admin'],
//     icon: 'clipboard',
//     children: [
//         {
//             path: '/quiz/list',
//             name: 'List',
//             component: QuizList,
//             route: PrivateRoute,
//         },
//         {
//             path: '/quiz/add-new',
//             name: 'Add New',
//             component: CreateQuiz,
//             route: PrivateRoute,
//         },
//     ],
// };

const contestAppRoutes: RoutesProps = {
    path: '/contest',
    name: 'Contest',
    route: PrivateRoute,
    roles: ['admin'],
    icon: 'clipboard',
    children: [
        {
            path: '/contest/list',
            name: 'List',
            component: ContestList,
            route: PrivateRoute,
        },
        {
            path: '/contest/add-new',
            name: 'Add New',
            component: CreateContest,
            route: PrivateRoute,
        },
        {
            path: '/contest/edit-contest',
            name: 'Edit Contest',
            component: EditContest,
            route: PrivateRoute,
        },
    ],
};

const predictionAppRoutes: RoutesProps = {
    path: '/prediction',
    name: 'Prediction',
    route: PrivateRoute,
    roles: ['admin'],
    icon: 'clipboard',
    children: [
        {
            path: '/prediction/list',
            name: 'List',
            component: PredictionList,
            route: PrivateRoute,
        },
        {
            path: '/prediction/add-new',
            name: 'Add New',
            component: CreatePrediction,
            route: PrivateRoute,
        },
        {
            path: '/prediction/edit-prediction',
            name: 'Edit Prediction',
            component: EditPrediction,
            route: PrivateRoute,
        },
        {
            path: '/prediction/update-prediction-answers',
            name: 'Update Prediction Answers',
            component: UpdatePredictionAnswers,
            route: PrivateRoute,
        },
    ],
};




// auth
const authRoutes: RoutesProps[] = [
    {
        path: '/auth/login',
        name: 'Login',
        component: Login,
        route: Route,
    },
    {
        path: '/auth/confirm',
        name: 'Confirm',
        component: Confirm,
        route: Route,
    },
    {
        path: '/auth/forget-password',
        name: 'Forget Password',
        component: ForgetPassword,
        route: Route,
    },
    {
        path: '/auth/logout',
        name: 'Logout',
        component: Logout,
        route: Route,
    },
];



// flatten the list of all nested routes
const flattenRoutes = (routes: RoutesProps[]) => {
    let flatRoutes: RoutesProps[] = [];

    routes = routes || [];
    routes.forEach((item: RoutesProps) => {
        flatRoutes.push(item);

        if (typeof item.children !== 'undefined') {
            flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)];
        }
    });
    return flatRoutes;
};

// All routes
const authProtectedRoutes = [rootRoute, dashboardRoutes, categoryRoutes ,questionBankRoutes ,contestAppRoutes,predictionAppRoutes ,usersRoutes ,reportedIssueRoutes ,reportedQuestionRoutes ,winnerRanks];
const publicRoutes = [...authRoutes];

const authProtectedFlattenRoutes = flattenRoutes([...authProtectedRoutes]);
const publicProtectedFlattenRoutes = flattenRoutes([...publicRoutes]);
export { publicRoutes, authProtectedRoutes, authProtectedFlattenRoutes, publicProtectedFlattenRoutes };
