import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';

// helpers
import {
    contestQuestions as contestQuestionsApi,
    addContestQuestion as addContestQuestionApi,
    updateContestQuestion as updateContestQuestionApi,
    deleteContestQuestion as deleteContestQuestionApi,
    importContestQuestions as importContestQuestionsApi,
} from '../../helpers';

// actions
import { contestQuestionApiResponseSuccess, contestQuestionApiResponseError } from './actions';

// constants
import { ContestQuestionActionTypes } from './constants';

interface ContestQuestionData {
    payload: {
        id: string;
        question: string;
        type: string;
        category: string;
        level: string;
        media: string;
        updateMedia: boolean;
        options: Array<{ option: string, selected: boolean }>;
        regions: any;
        sortBy: string
        limit: number
        page: number
        file: File
    };
    type: string;
}



/**
 * ContestQuestion list
 */
function* contestQuestionList({ payload: { question, regions: string, category, sortBy, limit, page, type } }: ContestQuestionData): SagaIterator {
    try {

        const response = yield call(contestQuestionsApi, { question, regions: string, category, sortBy, limit, page, type });
         yield put(contestQuestionApiResponseSuccess(
            ContestQuestionActionTypes.CONTEST_QUESTION_LIST,
            response.data.results,
            response.data.page,
            response.data.limit,
            response.data.totalPages,
            response.data.totalResults,
            response.data.categories,
            '',
            response.data.winnerCoinRanks,
        ));
    } catch (error: any) {
        yield put(contestQuestionApiResponseError(ContestQuestionActionTypes.CONTEST_QUESTION_LIST, error));

    }
}

/**
 * Add ContestQuestion
 */
function* addContestQuestion({ payload: { question, type, options, level, regions, category, media } }: ContestQuestionData): SagaIterator {
    try {

        const response = yield call(addContestQuestionApi, { question, type, options, level, category, regions, media });

        yield put(contestQuestionApiResponseSuccess(
            ContestQuestionActionTypes.ADD_CONTEST_QUESTION,
            response.data.data
        ));
    } catch (error: any) {
        yield put(contestQuestionApiResponseError(ContestQuestionActionTypes.ADD_CONTEST_QUESTION, error));

    }
}

/**
 * Update ContestQuestion
 */
function* updateContestQuestion({ payload: { id, question, type, category, options, level, regions, media, updateMedia } }: ContestQuestionData): SagaIterator {
    try {

        const response = yield call(updateContestQuestionApi, { question, category, type, options, level, regions, media, updateMedia }, id);

        yield put(contestQuestionApiResponseSuccess(
            ContestQuestionActionTypes.UPDATE_CONTEST_QUESTION,
            response.data.data
        ));
    } catch (error: any) {
        yield put(contestQuestionApiResponseError(ContestQuestionActionTypes.UPDATE_CONTEST_QUESTION, error));

    }
}
/**
 * Delete ContestQuestion
 */
function* deleteContestQuestion({ payload: { id } }: ContestQuestionData): SagaIterator {
    try {

        yield call(deleteContestQuestionApi, id);
        yield put(contestQuestionApiResponseSuccess(
            ContestQuestionActionTypes.DELETE_CONTEST_QUESTION,
            id
        ));
    } catch (error: any) {
        yield put(contestQuestionApiResponseError(ContestQuestionActionTypes.DELETE_CONTEST_QUESTION, error));

    }
}
/**
 * Import Contest Questions
 */
function* importContestQuestions({ payload: {
    regions,
    category,
    file, } }: ContestQuestionData): SagaIterator {
    try {

        const response = yield call(importContestQuestionsApi, {
            regions,
            category,
            file,
        });
        yield put(contestQuestionApiResponseSuccess(
            ContestQuestionActionTypes.IMPORT_CONTEST_QUESTION,
            response.data.data
        ));
    } catch (error: any) {
        yield put(contestQuestionApiResponseError(ContestQuestionActionTypes.IMPORT_CONTEST_QUESTION, error));

    }
}




export function* watchContestQuestionList() {
    yield takeEvery(ContestQuestionActionTypes.CONTEST_QUESTION_LIST, contestQuestionList);
}

export function* watchAddContestQuestion() {
    yield takeEvery(ContestQuestionActionTypes.ADD_CONTEST_QUESTION, addContestQuestion);
}

export function* watchUpdateContestQuestion() {
    yield takeEvery(ContestQuestionActionTypes.UPDATE_CONTEST_QUESTION, updateContestQuestion);
}

export function* watchDeleteContestQuestion() {
    yield takeEvery(ContestQuestionActionTypes.DELETE_CONTEST_QUESTION, deleteContestQuestion);
}

export function* watchImportContestQuestions() {
    yield takeEvery(ContestQuestionActionTypes.IMPORT_CONTEST_QUESTION, importContestQuestions);
}




function* contestQuestionSaga() {
    yield all([fork(watchContestQuestionList), fork(watchAddContestQuestion), fork(watchUpdateContestQuestion), fork(watchDeleteContestQuestion), fork(watchImportContestQuestions)]);
}

export default contestQuestionSaga;
